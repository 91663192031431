/* eslint-disable import/order */
import React, {useState} from 'react';
import {View, Text, FlatList} from 'react-native';

import {useAssets} from '../../context/assets-context/AssetsContext';
import EventInfoModal from '../../components/EventInfoModal.web';
import EventTile from '../../components/EventTile';
import AddSquare from '../../components/AddSquare.web.js';
import Search from '../../components/Search.js';
import {useWindowDimensions} from '../../utils/common/hooks';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import SkeletonTile from '../../components/SkeletonTile';
import {faMountainCity} from '@fortawesome/free-solid-svg-icons';

const TILE_HEIGHT = 240;
const TILE_WIDTH = 300;
const TILE_SPACING = 20;
const EXTRA_PADDING = 50;

export default function EventManager({navigation, route}) {
  const {events, selectEvent} = useAssets();
  const {style, values} = useTheme(getThemedStyles);
  const {navigate} = navigation;
  const [status, setStatus] = useState(STATUS.IDLE);
  const [modalMode, setModalMode] = useState(null);
  const {width} = useWindowDimensions();
  const [eventQuery, setEventQuery] = useState('');

  const filteredEvents =
    eventQuery.length > 0
      ? events.filter((event) =>
          event.name.toLowerCase().includes(eventQuery.toLowerCase()),
        )
      : events;

  // Calculate how many tiles fit
  let numColumns = Math.floor(
    (width - EXTRA_PADDING) / (TILE_WIDTH + TILE_SPACING),
  );
  if (numColumns > 5) {
    numColumns = 5;
  }

  function onModalClose() {
    if (status === STATUS.IDLE) {
      setStatus(STATUS.BUSY);
      setModalMode(null);
      setStatus(STATUS.IDLE);
    }
  }

  function navigateToSelectedEvent(item) {
    selectEvent(item.name)
      .then(() => {
        navigate({
          name: 'Asset Manager',
          params: {event: item.name, pack: undefined},
          merge: true,
        });
      })
      .catch(console.error);
  }

  return (
    <>
      <View style={style.searchBar}>
        <Search
          placeholder="Filter by name"
          style={style.search}
          textboxStyle={style.searchTextbox}
          onChangeText={(text) => setEventQuery(text)}
        />
      </View>
      <View style={{padding: 40}}>
        <View style={style.contentContainer}>
          <Text style={style.headerText}>
            {`Events (${filteredEvents ? filteredEvents?.length : '0'})`}
          </Text>
          {modalMode ? (
            <EventInfoModal onPressClose={onModalClose} mode={modalMode} />
          ) : null}
          <FlatList
            style={style.eventList}
            data={
              events
                ? [
                    ...(filteredEvents || []),
                    {name: 'Add Event', addEvent: true},
                  ]
                : [{name: 1}, {name: 2}, {name: 3}, {name: 4}]
            }
            keyExtractor={(event) => event.name}
            numColumns={numColumns}
            key={numColumns}
            ListEmptyComponent={
              <Text>Click 'New Event' to make an event!</Text>
            }
            showsVerticalScrollIndicator={false}
            extraData={events}
            renderItem={({item}) => {
              if (events === null) {
                return (
                  <SkeletonTile
                    style={{margin: 10}}
                    baseColor={values.DISABLED}
                    icon={faMountainCity}
                  />
                );
              }
              if (item.addEvent) {
                return (
                  <AddSquare
                    style={[
                      style.eventTile,
                      {height: TILE_HEIGHT, width: TILE_WIDTH},
                    ]}
                    onPress={() => setModalMode('new')}
                    text="Create Event"
                  />
                );
              }
              return (
                <EventTile
                  style={[
                    style.eventTile,
                    {height: TILE_HEIGHT, width: TILE_WIDTH},
                  ]}
                  event={item}
                  onSelectEvent={() => navigateToSelectedEvent(item)}
                />
              );
            }}
          />
          {events?.length ? null : (
            <Text style={style.informationText}>
              Create a new event to get started
            </Text>
          )}
          <View style={events ? {} : style.mask} />
        </View>
      </View>
    </>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  contentContainer: {
    backgroundColor: theme.BGFIRST,
    borderRadius: 20,
    alignItems: 'center',
    alignSelf: 'center',
    padding: 40,
    maxWidth: 1820,
  },
  eventList: {
    marginBottom: 40,
  },
  eventTile: {
    margin: 10,
  },
  headerText: {
    fontSize: fontSize.header,
    marginBottom: 40,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  informationText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    textAlign: 'center',
  },
  mask: {
    position: 'absolute',
    inset: 0,
    background: `linear-gradient(to bottom, ${theme.BGFIRST}00 0%, ${theme.BGFIRST}22 10%, ${theme.BGFIRST}FF 90%)`,
  },
  search: {
    paddingVertical: 10,
    borderRadius: 10,
    width: 250,
    mobile: {
      padding: 0,
      paddingVertical: 10,
    },
  },
  searchBar: {
    backgroundColor: theme.BGFIRST,
    width: '100%',
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
  },
  searchTextbox: {
    borderRadius: 15,
    borderWidth: 2,
    width: '100%',
  },
});
