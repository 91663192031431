import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {func, string, bool} from 'prop-types';
import React, {useState} from 'react';
import {View, TextInput} from 'react-native';

import EtherButton from './EtherButton';
import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';

Search.propTypes = {
  onChangeText: func.isRequired,
  placeholder: string,
  secureTextEntry: bool,
  value: string,
};

export default function Search({
  onChangeText,
  placeholder,
  secureTextEntry,
  keyboardType,
  value = '',
  style: overrides,
  textboxStyle: textboxOverrides,
  ...rest
}) {
  const [focus, setFocus] = useState(false);
  const [query, setQuery] = useState('');

  function onSearchChange(e) {
    setQuery(e);
    onChangeText(e);
  }

  const {style, values} = useTheme(getThemedStyles);

  return (
    <View style={[style.main, overrides]}>
      <View
        style={[
          focus ? style.containerFocus : style.containerBlur,
          textboxOverrides,
        ]}
      >
        <TextInput
          onChangeText={onSearchChange}
          placeholder={placeholder}
          style={style.input}
          autoCorrect={false}
          autoCapitalize="none"
          value={query}
          secureTextEntry={secureTextEntry}
          keyboardType={keyboardType}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          {...rest}
        />
        {query ? (
          <EtherButton
            basic
            style={style.icon}
            onPress={() => onSearchChange('')}
          >
            <FaIcon icon={faTimes} color={values.SECOND} size={25} />
          </EtherButton>
        ) : (
          <FaIcon
            style={style.icon}
            icon={faSearch}
            color={values.SECOND}
            size={25}
          />
        )}
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  containerBlur: {
    justifyContent: 'center',
    width: '100%',
    borderColor: theme.BGSECOND,
    height: 45,
    backgroundColor: theme.BGSECOND,
  },
  containerFocus: {
    justifyContent: 'center',
    width: '100%',
    borderWidth: 2,
    borderColor: theme.FIRST,
    height: 45,
    backgroundColor: theme.BGSECOND,
  },
  icon: {
    position: 'absolute',
    right: 10,
  },
  input: {
    marginLeft: 15,
    fontSize: 14,
    color: theme.DARK,
  },
  main: {
    backgroundColor: theme.BGFIRST,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: theme.FIRST,
  },
});
