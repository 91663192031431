import {func, bool, node} from 'prop-types';
import React, {useState, useRef, useEffect, useCallback} from 'react';
import {Text, View} from 'react-native';

import {useTheme} from '../context/ThemeContext';

const WARNING_TIMEOUT_DURATION = 120000; // two minutes
const FINAL_TIMEOUT_DURATION = 15000; // fifteen seconds

IdleTimer.proptypes = {
  onTimeout: func, // callback when the app is idle
  active: bool, // if we should show the warning
  children: node, // wrapped for touch events and modal takeover
};
export default function IdleTimer({
  onTimeout = () => {},
  active = true,
  children,
}) {
  const activeRef = useRef(active);
  const [showWarning, setShowWarning] = useState(false);
  const warningRef = useRef(showWarning);
  const finalTimeout = useRef(null);
  const warningTimeout = useRef(null);
  const {style} = useTheme(getThemedStyles);

  const onFinalTimeout = useCallback(() => {
    if (activeRef.current && warningRef.current) {
      setShowWarning(false);
      onTimeout();
    }
  }, [onTimeout]);

  const onWarningTimeout = useCallback(() => {
    if (activeRef.current) {
      setShowWarning(true);
      clearTimeout(finalTimeout.current);
      finalTimeout.current = setTimeout(onFinalTimeout, FINAL_TIMEOUT_DURATION);
    }
  }, [onFinalTimeout]);

  const startTimeout = useCallback(() => {
    clearTimeout(warningTimeout.current);
    warningTimeout.current = setTimeout(
      onWarningTimeout,
      WARNING_TIMEOUT_DURATION,
    );
  }, [onWarningTimeout]);

  const onInteraction = useCallback(() => {
    setShowWarning(false);
    clearTimeout(warningTimeout.current);
    clearTimeout(finalTimeout.current);
    startTimeout();
  }, [startTimeout]);

  useEffect(() => {
    window.addEventListener('click', onInteraction);
    window.addEventListener('touchstart', onInteraction);
    return () => {
      window.removeEventListener('click', onInteraction);
      window.removeEventListener('touchstart', onInteraction);
    };
  }, [onInteraction]);

  useEffect(() => {
    if (active) {
      startTimeout();
    }
  }, [active, startTimeout]);

  useEffect(() => {
    activeRef.current = active;
    warningRef.current = showWarning;
  }, [active, showWarning]);

  useEffect(() => {
    activeRef.current = active;
    warningRef.current = showWarning;
  }, [active, showWarning]);

  return (
    <View style={{flex: 1}}>
      {children}
      {showWarning && active ? (
        <View style={style.timeoutModal} transparent>
          <View style={style.timeoutModalOuter}>
            <View style={style.timeoutModalInner}>
              <Text style={style.timeoutText}>
                Your session is about to expire. Press anywhere to continue.
              </Text>
            </View>
          </View>
        </View>
      ) : null}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  timeoutModal: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  timeoutModalInner: {
    height: 100,
    width: 255,
    backgroundColor: theme.LIGHT,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    borderRadius: 5,
  },
  timeoutModalOuter: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.BLACKGROUND,
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeoutText: {
    textAlign: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
  },
});
