import React from 'react';
import {View, Text} from 'react-native';

import EtherMediaFullLogo from './EtherMediaLogoSVG';
import {useTheme} from '../context/ThemeContext';

function PoweredBy() {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.container}>
      <Text style={style.text}>powered by</Text>
      <EtherMediaFullLogo />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  container: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 180,
    height: 80,
  },
  text: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    letterSpacing: 5,
    marginBottom: 10,
  },
});

export default PoweredBy;
