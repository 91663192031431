import React from 'react';
import CookieConsent from 'react-cookie-consent';
import {View, Text} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import {setupLogrocketWeb} from '../utils/common/funcs';

export default function Cookies() {
  const {style} = useTheme(getThemedStyles);

  return (
    <CookieConsent
      location="bottom"
      buttonText="ACCEPT AND CLOSE"
      cookieName="EtherMediaCookieConsent"
      style={style.consent}
      buttonStyle={style.button}
      expires={90} //days
      onAccept={setupLogrocketWeb} // initialize tracking
    >
      <View style={style.textContainer}>
        <Text style={style.header}>We use cookies</Text>
        <Text style={style.body}>
          By continuing to browser or by clicking 'Accept', you agree to the
          storing of cookies on your device to enhance your site experience and
          for analytical purposes.
        </Text>
        <Text style={style.policyLink}>
          To learn more about how we use cookies, read our cookies policy here.
        </Text>
      </View>
    </CookieConsent>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  body: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
  },
  button: {
    width: 220,
    height: 40,
    borderColor: theme.LIGHT,
    borderWidth: 3,
    borderRadius: 5,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
  consent: {
    width: 'calc(100% - 15px)',
    backgroundColor: theme.FIRST,
  },
  header: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    marginBottom: 8,
  },
  policyLink: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
  },
  textContainer: {
    flexDirection: 'column',
  },
});
