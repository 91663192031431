import React from 'react';

import EtherInput from './EtherInput';

function cleanPrice(price) {
  return price.replace(/\D/g, '');
}

function formatUsdValue(cleanedPrice) {
  const paddedPrice = cleanedPrice.padStart(3, '0');
  const trimmedPrice = paddedPrice.replace(/^0+(?=\d)/, '');
  return `${trimmedPrice.slice(
    0,
    trimmedPrice.length - 2,
  )}.${trimmedPrice.slice(-2)}`;
}

export default function MoneyInput({
  value = '0',
  onChange = () => {},
  ...rest
}) {
  const cleanedPrice = cleanPrice(value);
  const usdValue = formatUsdValue(cleanedPrice);

  return (
    <EtherInput
      value={usdValue}
      money
      onChangeText={(text) => onChange(cleanPrice(text))}
      type="number"
      keyboardType="numeric"
      {...rest}
    />
  );
}
