import {
  faAngleLeft,
  faAngleRight,
  faArrowRotateLeft,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {func, bool} from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Modal,
  TouchableOpacity,
  Pressable,
  Text,
  Switch,
  View,
} from 'react-native';
import {ActivityIndicator} from 'react-native-web';

import EtherButton from './EtherButton';
import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {imgIsCached, imgOnLoad} from '../utils/common/funcs';
import {assetType} from '../utils/common/types';

AssetViewer.propTypes = {
  asset: assetType,
  onPressClose: func,
  onPressLeft: func,
  onPressRight: func,
  visible: bool,
};
export default function AssetViewer({
  asset = null,
  onPressClose = () => {},
  onPressLeft = () => {},
  onPressRight = () => {},
  visible,
}) {
  const {style, values} = useTheme(getThemedStyles);
  const isImage = asset?.mime.includes('image');
  const videoRef = useRef(null);
  const isUserFacing = /(store|kiosk)/.test(window.location.href);
  const [showOriginal, setShowOriginal] = useState(false);
  const [showReload, setShowReload] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(
    isImage ? imgIsCached(asset?.preview.url) : false,
  );

  const imageSrc = showOriginal ? asset?.original.url : asset?.preview.url;

  const onClose = useCallback(() => {
    setShowOriginal(false);
    onPressClose();
  }, [onPressClose]);

  useEffect(() => {
    if (!isImage) return () => {};
    const isCurrentCached = imgIsCached(imageSrc);
    setImgLoaded(isCurrentCached);
    if (!isCurrentCached) {
      imgOnLoad(imageSrc, () => setImgLoaded(true));
    }
  }, [asset?.preview.url, imageSrc, isImage]);

  const keyHandler = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowLeft':
          onPressLeft();
          break;
        case 'ArrowRight':
          onPressRight();
          break;
        case 'Escape':
          onClose();
          break;
        default:
      }
    },
    [onPressLeft, onPressRight, onClose],
  );

  // Handle keys
  useEffect(() => {
    window.addEventListener('keydown', keyHandler);
    return () => {
      window.removeEventListener('keydown', keyHandler);
    };
  }, [keyHandler]);

  function onReloadVideo() {
    videoRef.current.load();
    videoRef.current
      .play()
      .catch((err) => console.error(`couldn't play video ${err.message}`));
    setShowReload(false);
  }

  return (
    <Modal
      style={style.modal}
      visible={visible}
      transparent
      animationType="fade"
    >
      <Pressable style={style.outerArea} onPress={onClose} />
      <TouchableOpacity style={style.leftButton} onPress={onPressLeft}>
        <FaIcon icon={faAngleLeft} color={values.BGSECOND} size={40} />
      </TouchableOpacity>
      <TouchableOpacity style={style.rightButton} onPress={onPressRight}>
        <FaIcon icon={faAngleRight} color={values.BGSECOND} size={40} />
      </TouchableOpacity>
      <TouchableOpacity style={style.closeButton} onPress={onClose}>
        <FaIcon icon={faTimes} color={values.BGSECOND} size={40} />
      </TouchableOpacity>
      <Text textAlign="center" style={style.name}>
        {asset?.name}
      </Text>
      {visible && isImage ? (
        <img
          src={imgLoaded ? imageSrc : asset?.thumb.url}
          style={{
            ...style.image,
            ...(!imgLoaded ? {filter: 'blur(5px)'} : {}),
          }}
          className={imgLoaded ? 'blur-in' : 'blur-out'}
        />
      ) : null}
      {visible && asset.mime.includes('video') ? (
        <>
          <video
            // The key forces react to destroy and re-create the dom element
            // which allows the video controls to update when switching
            // between the preview and original
            key={showOriginal ? asset.original.url : asset.preview.url}
            style={style.video}
            poster={asset.poster.url}
            src={showOriginal ? asset.original.url : asset.preview.url}
            controls={asset.progress === 'complete'}
            ref={videoRef}
            // Safari in power saving mode only loads metadata
            onLoadedMetadata={() => setShowReload(false)}
            onError={() => {
              setShowReload(true);
            }}
          />
          {showReload && asset.progress === 'complete' ? (
            <View style={style.scrimContainer}>
              <EtherButton onPress={onReloadVideo} style={{height: null}}>
                <FaIcon
                  icon={faArrowRotateLeft}
                  color={values.BGSECOND}
                  size={20}
                  style={{padding: 10}}
                />
              </EtherButton>
              {/* FireFox doesn't play certain types of MOV */}
              {showOriginal ? (
                <Text style={style.reloadText}>
                  This original format
                  <br />
                  may not be playable
                  <br /> in your browser
                </Text>
              ) : null}
            </View>
          ) : null}
          {asset.progress === 'processing' ? (
            <View style={style.scrimContainer}>
              <ActivityIndicator size="large" color={values.LIGHT} />
              <Text style={[style.labelText, {paddingTop: 4}]}>
                Processing...
              </Text>
            </View>
          ) : null}
        </>
      ) : null}
      {asset?.progress === 'complete' && !isUserFacing ? (
        <View style={style.switchContainer}>
          <Text style={style.labelText}>preview</Text>
          <Switch
            style={style.switch}
            trackColor={{false: values.FIRST, true: values.FIRST}}
            thumbColor={values.BGFIRST}
            activeThumbColor={values.BGFIRST}
            onValueChange={() => setShowOriginal(!showOriginal)}
            value={showOriginal}
          />
          <Text style={style.labelText}>original</Text>
        </View>
      ) : null}
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  image: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    objectFit: 'contain',
    maxHeight: 'calc(100vh - 160px)',
    maxWidth: 'calc(100vw - 160px)',
    filter: 'blur(0px)',
    width: '100%',
  },
  labelText: {
    fontFamily: 'NotoSans_Regular',
    color: theme.LIGHT,
    fontSize: fontSize.body,
  },
  leftButton: {
    position: 'fixed',
    top: '50%',
    left: 20,
  },
  modal: {
    inset: 0,
    position: 'fixed',
  },
  name: {
    position: 'absolute',
    top: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
    fontSize: fontSize.header,
  },
  outerArea: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    borderColor: theme.DARK,
    backgroundColor: 'rgba(0, 0, 0, 0.98)',
  },
  reloadText: {
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
    fontSize: fontSize.legal,
    textAlign: 'center',
    marginTop: 4,
  },
  rightButton: {
    position: 'fixed',
    top: '50%',
    right: 20,
  },
  scrimContainer: {
    position: 'absolute',
    left: '50%',
    top: 'calc(50% - 60px)',
    transform: 'translate3d(-50%, -50%, 0)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'radial-gradient(circle, #000A 0%, #0004 40%, #00000007 50%, #0000 100%)',
    borderRadius: '50%',
    padding: 64,
    opacity: 0.9,
  },
  subContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
  },
  switch: {
    marginHorizontal: 20,
  },
  switchContainer: {
    position: 'absolute',
    bottom: 40,
    height: 30,
    left: '50%',
    transform: 'translateX(-50%)',
    flexDirection: 'row',
    alignItems: 'center',
  },
  video: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    objectFit: 'contain',
    maxHeight: 'calc(100vh - 160px)',
    maxWidth: 'calc(100vw - 160px)',
    filter: 'blur(0px)',
  },
});
