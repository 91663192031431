import {
  faCheck,
  faDownload,
  faEllipsisV,
  faFileImage,
  faFileVideo,
  faFolder,
  faFolderTree,
  faPencilAlt,
  faTrashAlt,
  faVideo,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import {func, string} from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  ActivityIndicator,
  Image,
  Modal,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import EtherButton from './EtherButton';
import EtherInput from './EtherInput';
import FaIcon from './FaIcon';
import SimpleDropdown from './SimpleDropdown.web';
import SkeletonTile from './SkeletonTile';
import useDropdown from '../context/DropdownContext';
import {useOperator} from '../context/OperatorContext';
import {useTheme} from '../context/ThemeContext';
import {useAssets} from '../context/assets-context/AssetsContext';
import {STATUS} from '../utils/common/constants';
import {downloadItem} from '../utils/common/download';
import {
  ellipsify,
  formatBytes,
  imgIsCached,
  secondsToTimestamp,
} from '../utils/common/funcs';
import {assetType} from '../utils/common/types';

function RenameModal({onClose, name, onChangeName, show, onRename, loading}) {
  function downHandler({key}) {
    if (key === 'Enter') {
      onRename();
    }
    if (key === 'Escape') {
      onClose();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });

  const {style} = useTheme(getThemedStyles);
  return (
    <Modal
      useNativeDriver={false}
      animationType="fade"
      transparent
      visible={show}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      {/* TODO: Turn into EtherDialog/EtherModal*/}
      <TouchableOpacity
        style={style.modalBackdrop}
        onPress={onClose}
        activeOpacity={1}
      />
      <View style={style.modalContainer}>
        <View style={[style.modal, style.renameModal]}>
          <EtherInput
            placeholder="Current file name"
            value={name}
            onChangeText={onChangeName}
            title="Rename to"
            style={style.input}
            onKeyPress={downHandler}
            autoFocus
          />
          <View style={style.modalRow}>
            <EtherButton
              onPress={onClose}
              style={style.closeButton}
              disabled={loading}
            >
              <Text style={style.closeText}>Close</Text>
            </EtherButton>
            <EtherButton
              onPress={onRename}
              style={style.modalButton}
              status={loading ? STATUS.BUSY : STATUS.IDLE}
            >
              <Text style={style.modalButtonText}>Confirm</Text>
            </EtherButton>
          </View>
        </View>
      </View>
    </Modal>
  );
}

function DeleteModal({onClose, show, onDelete, loading}) {
  function downHandler({key}) {
    if (key === 'Enter') {
      onDelete();
    }
    if (key === 'Escape') {
      onClose();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });

  const {style} = useTheme(getThemedStyles);
  return (
    <Modal
      useNativeDriver={false}
      animationType="fade"
      transparent
      visible={show}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <TouchableOpacity
        style={style.modalBackdrop}
        onPress={onClose}
        activeOpacity
      />
      <View style={style.modalContainer}>
        <View style={[style.modal, style.deleteModal]}>
          <Text style={style.deleteHeader}>Are you sure?</Text>
          <Text style={style.deleteSub}>This action is permanent.</Text>
          <View style={style.modalRow}>
            <EtherButton
              style={style.closeButton}
              onPress={onClose}
              disabled={loading}
            >
              <Text style={style.closeText}>Cancel</Text>
            </EtherButton>
            <EtherButton
              onPress={onDelete}
              style={style.modalButton}
              status={loading ? STATUS.BUSY : STATUS.IDLE}
            >
              <Text style={style.modalButtonText}>Delete</Text>
            </EtherButton>
          </View>
        </View>
      </View>
    </Modal>
  );
}

Details.propType = {
  meta: assetType.isRequired,
};
function Details({meta, operator}) {
  const [mouseHover, setMouseHover] = useState(false);

  const {style, values} = useTheme(getThemedStyles);
  const formattedSize = meta.bytes ? formatBytes(meta.bytes) : NaN;

  const keyParts = meta.original.key.split('/');
  const displayKeyParts = keyParts.slice(2, -1);
  const locationPath = [
    operator.op,
    displayKeyParts[0],
    displayKeyParts[1],
  ].join('/');
  const displayLocation = ellipsify(locationPath, 36);

  return (
    <View style={style.optionBar}>
      <View
        style={[style.subOptionBar, style.subOptionBarTop]}
        onMouseOver={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
      >
        {mouseHover && meta.name.length > 28 ? (
          <Text style={style.nameLong}>{meta.name}</Text>
        ) : (
          <Text style={style.name}>{ellipsify(meta.name, 28)}</Text>
        )}
      </View>
      <View style={[style.subOptionBar, style.subOptionBarMid]}>
        <FaIcon
          icon={faFolder}
          size={15}
          color={values.FIRST}
          style={style.folder}
        />
        <Text style={style.location}>{displayLocation}</Text>
      </View>
      <View style={[style.subOptionBar, style.subOptionBarBot]}>
        <FaIcon
          icon={meta.mime.includes('image') ? faFileImage : faFileVideo}
          size={13}
          color={values.FIRST}
          style={style.typeident}
        />
        <View style={style.botBarDetails}>
          <Text style={style.filetype}>{meta.mime.split('/')[1]}</Text>
          {formattedSize ? (
            <Text style={style.filesize}>{formattedSize}</Text>
          ) : (
            <Skeleton width={50} height={12} />
          )}
          {meta.width && meta.height ? (
            <Text style={style.dimensions}>
              {meta.width} x {meta.height}
            </Text>
          ) : (
            <Skeleton width={100} height={12} />
          )}
        </View>
      </View>
    </View>
  );
}

Overlay.propType = {
  meta: assetType.isRequired,
};
function Overlay({meta}) {
  const {style, values} = useTheme(getThemedStyles);

  if (meta.progress === 'processing') {
    return (
      <View style={style.progressContainer}>
        <ActivityIndicator size="large" color={values.FIRST} />
        <Text style={style.progressText}>
          Tip: Videos won't show up on mobile until processing is complete
        </Text>
      </View>
    );
  }
  if (meta.mime.includes('video')) {
    return (
      <>
        <FaIcon size={100} icon={faVideo} style={style.videoIcon} />
        <Text style={style.duration}>{secondsToTimestamp(meta.duration)}</Text>
      </>
    );
  } else {
    return null;
  }
}

AdminGalleryTile.propTypes = {
  onPress: func,
  onSelectPress: func,
  selected: assetType,
  source: string.isRequired,
  meta: assetType,
};
export default function AdminGalleryTile({
  onPress = () => {},
  onSelectPress = () => {},
  preDeleteCallback = () => {},
  moveAsset = () => {},
  selected,
  style: overrides,
  source,
  meta,
  favoriteTitle = meta.favorite === true ? 'Unfavorite' : 'Favorite',
}) {
  const {deleteAssets, renameAsset, favoriteAsset, unfavoriteAsset} =
    useAssets();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {dropdownId} = useDropdown();
  const [thumbLoaded, setThumbLoaded] = useState(imgIsCached(source));
  const [isBusy, setIsBusy] = useState(false);
  const [newAssetName, setNewAssetName] = useState('');
  const isFavorite = meta.favorite;
  const kebabRef = useRef(null);
  const {style, values} = useTheme(getThemedStyles);
  const operator = useOperator();
  const [, event, pack] = meta.original.key
    .replace('ether-media/', '')
    .split('/');

  const menuEntries = [
    {
      title: 'Rename',
      faIcon: faPencilAlt,
      callback: () => setShowRenameModal(true),
    },
    {
      title: 'Move',
      faIcon: faFolderTree,
      callback: moveAsset,
    },
    {
      title: favoriteTitle,
      faIcon: faStar,
      callback: onFavorite,
    },
    {
      title: 'Download',
      faIcon: faDownload,
      callback: downloadAsset,
    },
    {
      title: 'Delete',
      faIcon: faTrashAlt,
      callback: () => setShowDeleteModal(true),
    },
  ];
  const assetComplete = meta.progress === 'complete';
  const activeMenuBtns = assetComplete ? menuEntries : [menuEntries.at(-1)];

  function downloadAsset() {
    downloadItem(`/packs/download/asset/${event}/${pack}/${meta.name}`);
  }

  function onFavorite() {
    setIsBusy(true);
    (meta.favorite === true
      ? unfavoriteAsset(meta.name)
      : favoriteAsset(meta.name)
    )
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsBusy(false);
      });
  }

  function onDelete() {
    setIsBusy(true);
    deleteAssets(meta.name)
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsBusy(false));
  }

  function onRename() {
    setIsBusy(true);
    renameAsset(meta.name, newAssetName)
      .catch((err) => console.error(err))
      .finally(() => setIsBusy(false));
  }

  return (
    <View style={[style.container, overrides]}>
      {!thumbLoaded ? (
        <SkeletonTile
          style={style.skeletonTile}
          icon={meta.mime.includes('image') ? faFileImage : faFileVideo}
        />
      ) : null}
      <RenameModal
        show={showRenameModal}
        onClose={() => setShowRenameModal(false)}
        onChangeName={setNewAssetName}
        name={newAssetName}
        loading={isBusy}
        onRename={onRename}
      />
      <DeleteModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={() => {
          preDeleteCallback();
          onDelete();
        }}
        loading={isBusy}
      />
      <EtherButton
        basic
        style={[style.container, {opacity: thumbLoaded ? 1 : 0}]}
        onPress={(e) => onPress(e)}
      >
        {dropdownId === meta._id ? (
          <SimpleDropdown style={style.dropdown} entries={activeMenuBtns} />
        ) : null}
        <View style={style.imageContainer}>
          <Overlay meta={meta} />
          <Image
            onLoad={() => {
              if (!thumbLoaded) {
                setThumbLoaded(true);
              }
            }}
            onError={() => setThumbLoaded(true)}
            style={style.image}
            source={source}
          />
        </View>
        <View style={style.starIconContainer}>
          {isFavorite ? (
            <EtherButton
              basic
              style={style.starIcon}
              icon={faStar}
              iconColor="#FFD700"
              iconSize={26}
              activeOpacity={1}
            />
          ) : null}
        </View>
        {selected ? (
          <View style={style.checkBoxFocus}>
            <EtherButton
              basic
              style={style.checkContainer}
              onPress={onSelectPress}
            >
              <FaIcon icon={faCheck} color={values.BGSECOND} />
            </EtherButton>
          </View>
        ) : (
          <View style={style.checkBoxBlur}>
            <EtherButton
              basic
              style={style.checkContainer}
              onPress={onSelectPress}
            />
          </View>
        )}
        <View style={style.menuIconContainer}>
          <EtherButton
            basic
            style={style.menuIcon}
            ref={kebabRef}
            dataSet={{dropdownId: meta._id}}
            testID={`menu-${meta.name}`}
          >
            <FaIcon
              // no events so click listeners target the parent button
              style={{pointerEvents: 'none'}}
              icon={faEllipsisV}
              size={20}
              color={values.BGSECOND}
            />
          </EtherButton>
        </View>
        <Details meta={meta} operator={operator} />
      </EtherButton>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  botBarDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  checkBoxBlur: {
    flex: 1,
    position: 'absolute',
    marginTop: 5,
    marginLeft: 5,
    borderWidth: 2,
    borderRadius: 3,
    borderColor: theme.BGSECOND,
    backgroundColor: theme.BLACKGROUND,
  },
  checkBoxFocus: {
    flex: 1,
    position: 'absolute',
    marginTop: 5,
    marginLeft: 5,
    borderWidth: 2,
    borderRadius: 3,
    borderColor: theme.BGSECOND,
    backgroundColor: theme.BLACKGROUND,
  },
  checkContainer: {
    paddingLeft: 2,
    margin: 1,
    height: 17,
    width: 17,
  },
  closeButton: {
    backgroundColor: theme.BGFIRST,
    width: 80,
    marginRight: 15,
  },
  closeText: {
    fontSize: fontSize.legal,
    color: theme.FIRST,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  container: {
    height: 200,
    width: 270,
  },
  deleteHeader: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 5,
  },
  deleteModal: {
    width: 300,
  },
  deleteSub: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  dimensions: {
    marginRight: 3,
    color: theme.DARK,
  },
  dropdown: {
    width: 130,
    position: 'absolute',
    zIndex: 2,
    backgroundColor: theme.BGFIRST,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: theme.FIRST,
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    marginTop: 10,
    marginRight: 30,
  },
  duration: {
    color: theme.LIGHT,
    backgroundColor: 'black',
    padding: 3,
    zIndex: 99999,
    position: 'absolute',
    right: 2,
    bottom: 2,
    borderRadius: 5,
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
  },
  filesize: {
    color: theme.DARK,
  },
  filetype: {
    marginLeft: 3,
    color: theme.DARK,
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
  },
  folder: {
    marginLeft: 5,
    marginTop: 2,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    flex: 1,
  },
  input: {
    width: '100%',
  },
  location: {
    marginLeft: 3,
    marginTop: 2,
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
  },
  menuIcon: {
    padding: 10,
    filter: `drop-shadow(0px 0px 3px ${theme.DARK})`,
  },
  menuIconContainer: {
    alignSelf: 'flex-end',
    flex: 1,
    position: 'absolute',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 20,
  },
  modalBackdrop: {
    inset: 0,
    position: 'absolute',
    backgroundColor: theme.BLACKGROUND,
  },
  modalButton: {
    width: 80,
  },
  modalButtonText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  modalContainer: {
    margin: 'auto',
  },
  modalRow: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  name: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginLeft: 3,
  },
  nameLong: {
    width: '98%',
    marginLeft: 3,
    position: 'absolute',
    fontSize: fontSize.body,
    lineHeight: 22,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    backgroundColor: theme.BGFIRST,
  },
  optionBar: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 1,
    height: 69,
    zIndex: 1,
  },
  progressContainer: {
    position: 'absolute',
    backgroundColor: theme.DARK,
    opacity: 0.8,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '10px',
  },
  progressText: {
    color: theme.LIGHT,
    textAlign: 'center',
    fontSize: fontSize.legal,
  },
  renameModal: {
    height: 210,
    width: 380,
  },
  skeletonTile: {
    position: 'absolute',
    height: 200,
    width: 270,
  },
  starIcon: {
    filter: `drop-shadow(0px 2px 1px ${theme.DARK})`,
  },
  starIconContainer: {
    width: 26,
    height: 26,
    flex: 1,
    marginLeft: 2,
    bottom: 72,
    zIndex: 999,
    position: 'absolute',
  },
  subOptionBar: {
    height: 23,
    flexDirection: 'row',
    alignContent: 'center',
  },
  subOptionBarBot: {
    borderTopColor: theme.FIRST,
    borderTopWidth: 1,
  },
  subOptionBarMid: {
    zIndex: -1,
  },
  subOptionBarTop: {},
  typeident: {
    marginTop: 3,
    marginLeft: 3,
  },
  videoIcon: {
    position: 'absolute',
    zIndex: 9999,
    marginTop: 15,
    alignSelf: 'center',
    color: 'rgba(255, 255, 255, 0.8)',
  },
});
