import {
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import {bool, func, object} from 'prop-types';
import React, {useState, useEffect, useMemo} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import {AddPackModal} from '../../components/AddPackModal';
import AdminGallery from '../../components/AdminGallery.web';
import DirList from '../../components/DirList';
import EtherButton from '../../components/EtherButton';
import EtherDialog from '../../components/EtherDialog';
import EtherInput from '../../components/EtherInput';
import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {useAssets} from '../../context/assets-context/AssetsContext';
import {HEADER_HEIGHT, STATUS} from '../../utils/common/constants';
import {ellipsify} from '../../utils/common/funcs';
import {renamePack} from '../../utils/common/packs';

RenameModal.propTypes = {
  showing: bool.isRequired,
  pack: object,
  onClose: func.isRequired,
};

function RenameModal({showing, onClose, pack, initialName}) {
  const {dispatch} = useAssets();
  const [status, setStatus] = useState(STATUS.IDLE);
  const inputRef = React.useRef(null);
  const [newName, setNewName] = useState(initialName);

  // Focus and set input on show
  useEffect(() => {
    if (
      showing &&
      inputRef.current &&
      document.activeElement !== inputRef.current
    ) {
      if (initialName !== newName) setNewName(initialName);
      inputRef.current.focus();
    }
  }, [initialName, newName, showing]);

  return (
    <EtherDialog
      header="Rename Pack"
      controls={[
        {
          text: 'Cancel',
          disabled: status === STATUS.BUSY,
          onPress: onClose,
          status,
        },
        {
          text: 'Confirm',
          onPress: () => {
            setStatus(STATUS.BUSY);
            renamePack(pack._id, newName)
              .then(({pack: renamedPack}) => {
                dispatch({type: 'pack.rename', value: {pack: renamedPack}});
                onClose();
              })
              .catch(console.error)
              .finally(() => setStatus(STATUS.IDLE));
          },
          status,
          variant: 'primary',
        },
      ]}
      onClose={onClose}
      show={showing}
    >
      <EtherInput
        title="Pack Name"
        placeholder="Act 1"
        ref={inputRef}
        value={newName}
        onChangeText={setNewName}
        style={{marginBottom: 12}}
      />
    </EtherDialog>
  );
}

function AdminDirButton({title, onPress, onEdit, style: overrides, active}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <TouchableOpacity
      style={[style.listButton, active ? style.listButtonActive : null]}
      title={title}
      onPress={onPress}
    >
      <FaIcon
        icon={active ? faFolderOpen : faFolder}
        color={active ? values.LIGHT : values.DARK}
        size={30}
      />
      <Text style={[style.buttonText, active ? style.buttonTextActive : null]}>
        {ellipsify(title, 30)}
      </Text>
      <EtherButton
        style={{padding: 4}}
        icon={faPencilAlt}
        iconColor={active ? values.LIGHT : values.FIRST}
        onPress={() => onEdit(title)}
        basic
      />
    </TouchableOpacity>
  );
}

export default function AssetManager({navigation}) {
  const {
    currentPack,
    currentEvent,
    events,
    packs,
    selectPack,
    selectEvent,
    assets,
    moveAssets,
  } = useAssets();
  useEffect(() => window.scrollTo(0, 0), []);

  const [renamingTitle, setRenamingTitle] = useState(null);
  const packToRename = useMemo(
    () => packs?.find((pack) => pack.name === renamingTitle),
    [packs, renamingTitle],
  );

  const [assetsToMove, setAssetsToMove] = useState([]);
  const [show, setShow] = useState(false);
  const [showEventList, setShowEventList] = useState(false);
  const {style, values} = useTheme(getThemedStyles);

  function viewPack(chosenPack) {
    navigation.setParams({pack: chosenPack.name});
    selectPack(chosenPack.name);
  }

  if (!events) return null;
  return (
    <View style={style.masterContainer}>
      <RenameModal
        showing={!!renamingTitle}
        pack={packToRename}
        onClose={() => setRenamingTitle(null)}
        initialName={packToRename?.name}
      />

      <AddPackModal show={show} onHide={() => setShow(false)} />
      <View style={style.mainContainer}>
        <View style={style.leftPane}>
          <EtherButton
            style={style.leftPaneHeader}
            onPress={() => setShowEventList(!showEventList)}
          >
            <Text style={style.textHeader}>
              {showEventList ? 'Events:' : ellipsify(currentEvent?.name, 13)}
            </Text>
            {!showEventList ? (
              <EtherButton
                basic
                style={style.addPack}
                onPress={() => setShow(true)}
                testID="button-add-pack"
                icon={faFolderPlus}
                iconColor={values.BGSECOND}
                iconSize={28}
              />
            ) : null}
          </EtherButton>

          {showEventList ? (
            <DirList
              selected={currentEvent?.name}
              dirs={events}
              loaded={event !== null}
              onPress={(newEvent) => {
                navigation.setParams({event: newEvent.name, pack: undefined});
                selectEvent(newEvent.name).then(() => setShowEventList(false));
              }}
              searchStyle={style.searchStyle}
            />
          ) : (
            <DirList
              msgHeader="No Packs Found"
              msgBody="Create a pack to add content"
              selected={currentPack?.name}
              dirs={packs}
              loaded={packs !== null}
              renderItem={(props) => (
                <AdminDirButton
                  {...props}
                  onEdit={(title) => {
                    setRenamingTitle(title);
                  }}
                />
              )}
              onPress={
                assetsToMove.length
                  ? (targetPack) =>
                      moveAssets(targetPack, assetsToMove).then(() =>
                        setAssetsToMove([]),
                      )
                  : (newPack) => viewPack(newPack)
              }
              searchStyle={style.searchStyle}
            />
          )}
        </View>
        <View style={style.galleryContainer}>
          {assets ? (
            <AdminGallery
              showAddPackModal={() => setShow(true)}
              showEventList={showEventList}
              assetsToMove={assetsToMove}
              setAssetsToMove={setAssetsToMove}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
}

export const getThemedStyles = (theme, fontSize) => ({
  addPack: {
    border: 'none',
    paddingRight: 10,
    paddingVertical: 20,
  },
  buttonText: {
    color: theme.DARK,
    marginLeft: 10,
    fontFamily: 'NotoSans_Bold',
    flexGrow: 1,
  },
  buttonTextActive: {
    color: theme.LIGHT,
  },
  dialog: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    width: 380,
    padding: 20,
    cursor: 'default',
  },
  galleryContainer: {
    flex: 1,
  },
  leftPane: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 280,
    backgroundColor: theme.BGFIRST,
  },
  leftPaneHeader: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: 65,
    borderRadius: 0,
    borderWidth: 0,
    backgroundColor: theme.SECOND,
  },
  listButton: {
    padding: 10,
    backgroundColor: theme.BGFIRST,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listButtonActive: {
    backgroundColor: theme.SECOND,
    color: theme.LIGHT,
    shadowColor: theme.DARK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
  listStyle: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT + 40 + 65 + 20}px)`, // header + column header + search bar + margin
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.BGSECOND,
    overflow: 'hidden',
  },
  masterContainer: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    width: '100%',
  },
  searchStyle: {
    borderRadius: 10,
    margin: 10,
    height: 40,
    width: '95%',
    alignSelf: 'center',
  },
  textHeader: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
    color: theme.LIGHT,
  },
});
