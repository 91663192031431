import io from 'socket.io-client';

/**
 * Parse a query string into an object
 * @param {string} queryString - query param string i.e. 'foo=bar&biz=baz'
 * @returns {Object} - map of query string
 */
export function parseQueryString(queryString) {
  if (queryString.length === 0) return {};
  const queryPairs = queryString.replaceAll('?', '').split('&');
  const result = queryPairs.reduce((acc, pair) => {
    const [key, rawValue] = pair.split('=');
    const value = decodeURIComponent(rawValue);
    acc[key] = value;
    return acc;
  }, {});
  return result;
}

/**
 * A callback function to process a message with a given payload.
 *
 * @callback SocketHandler
 * @param {string} wsType - The type of the message.
 * @param {Object} payload - The payload of the message.
 * @returns {void}
 */

/**
 * Create our socket instance
 * @param {SocketHandler} onMessage - Callback function for incoming messages.
 * @param {string} passedId - The user ID to connect with.
 * @returns {import('socket.io-client').Socket | null} - Returns socket on success
 */
export function initSocket(onMessage, passedId = '') {
  const hasUserId =
    localStorage.userId &&
    localStorage.userId !== 'undefined' &&
    localStorage.userId !== 'null';
  if (!hasUserId && !passedId) return null;

  // TODO: Actually make sure they have permission to the socket
  const userId = passedId || localStorage.userId;
  const socket = io(window.location.origin, {query: `userId=${userId}`});

  socket.onAny(onMessage);

  socket.on('disconnect', (reason, details) => {
    console.log(
      'Socket disconnected\n',
      'Reason: ',
      reason,
      '\nDetails: ',
      details,
    );
  });

  return socket;
}
