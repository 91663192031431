import AsyncStorage from '@react-native-async-storage/async-storage';
import {Platform} from 'react-native';

import {PLATFORMS} from './constants';
import {parseQueryString} from '../../context/assets-context/util';

/**
 * @param {string} string - The string to truncate with ellipses
 * @param {number} max - Max character count to return
 * @return {string} Truncated string if it's longer than max
 */

export function ellipsify(string, max) {
  if (!string?.length || string.length <= max) return string;

  const start = string.substring(0, max * (1 / 3));
  const remainingChars = max - 3 - start.length;
  const end = string.slice(-remainingChars);
  return `${start}...${end}`;
}

const ENVS = {
  DEV: 'development',
  STAGE: 'staging',
  PROD: 'production',
};

export function isDev() {
  if (Platform.OS === PLATFORMS.web) {
    return window.location.hostname === 'localhost';
  }

  return process.env.NODE_ENV === ENVS.DEV;
}
export function isStage() {
  if (Platform.OS === PLATFORMS.web) {
    return window.location.hostname === 'staging.ethermedia.app';
  }

  return process.env.NODE_ENV === ENVS.STAGE;
}
export function isProd() {
  if (Platform.OS === PLATFORMS.web) {
    return window.location.hostname === 'ethermedia.app';
  }

  return process.env.NODE_ENV === ENVS.PROD;
}

export function createKey(len = 10) {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < len; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

export async function getToken() {
  if (Platform.OS === PLATFORMS.web) {
    return localStorage.token || null;
  }

  try {
    return (await AsyncStorage.getItem('token')) || null;
  } catch (e) {
    console.error(e);
  }
}

/**
 *
 * @param bytes - Bytes to format
 * @param decimals - Number of decimal places to round to
 * @returns {string}
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
}

export function conditionalS(number) {
  if (number > 1) {
    return 's';
  }
  return '';
}

/**
 *
 * @param {number} cents / the number to turn into Dollar and Cents
 * @returns {string}
 */
export function centsToDollars(cents) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100);
}

/**
 *
 * @param {string} date / turns 'August 19, 1975 23:15:30 GMT+00:00' into '11:15 PM'
 */
export function formatTime(date) {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(date).toLocaleTimeString('en-US', options);
}

/**
 *
 * @param {string} date / turns 'August 19, 1975 23:15:30 GMT+00:00' into 'Fri, August 9, 1975'
 */
export function formatDate(date) {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Date(date).toLocaleString('en-US', options);
}

/**
 * Use this if a value must be an array
 *
 * @param {any} value - value to be returned as array
 * @returns value, or value within an array
 *
 * e.g. 'foo' -> ['foo']
 * e.g. ['foo', 'bar'] -> ['foo', 'bar']
 */
export function asArray(value) {
  const result = Array.isArray(value) ? value : [value];
  return result;
}

/**
 *
 * @param {number} duration - duration in seconds
 * @returns duration in format hh:mm:ss
 */
export function secondsToTimestamp(duration) {
  let seconds = Math.floor(duration % 60);
  let minutes = Math.floor((duration / 60) % 60);
  let hours = Math.floor((duration / (60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  return hours + ':' + minutes + ':' + seconds;
}

// Initalize error and session recording
export async function setupLogrocketWeb() {
  const LogRocket = await import('logrocket');
  const setupLogRocketReact = await import('logrocket-react');
  LogRocket.init('wjcrbv/ether-web', {
    network: {
      requestSanitizer: (req) => {
        // Scrub passwords
        const passwordUrlParts = ['signup', 'login', 'resetpassword'];
        const lowercaseUrl = req.url.toLowerCase();
        if (passwordUrlParts.some((part) => lowercaseUrl.includes(part))) {
          let isJsonBody;
          try {
            JSON.parse(req.body);
            isJsonBody = true;
          } catch {
            isJsonBody = false;
          }

          if (isJsonBody) {
            const parsedBody = JSON.parse(req.body);
            parsedBody.password = 'REDACTED';
            req.body = JSON.stringify(parsedBody);
          }
        }

        return req;
      },
    },
  });
  setupLogRocketReact(LogRocket);
}

/**
 * Clear any user session data stored in localStorage.
 */
export function clearLocalStorageSessionInfo() {
  localStorage.token = '';
  localStorage.username = '';
  localStorage.userId = '';
}

/**
 * Check if an image is cached by the browser.
 *
 * @param src - url of an image to check
 * @returns {boolean}
 */
export function imgIsCached(src) {
  const image = new window.Image();
  image.src = src;
  return image.complete;
}

/**
 * Cache an image, do something when it loads in.
 * Returns almost instantly if already cached.
 * @param src img url to cache
 * @param cb callback to run when image is loaded
 * @returns {*}
 */
export function imgOnLoad(src, cb) {
  const image = new window.Image();
  image.src = src;
  image.onload = cb;
}

export function saveSettings(newSettings) {
  try {
    localStorage.setItem('settings', JSON.stringify(newSettings));
  } catch (err) {
    throw "Couldn't save settings" + err;
  }
}

/**
 * Make a string kebab-case
 * "HOw are yOU DOINg?" -> "how-are-you-doing?"
 * @param str
 */
export function kebab(str) {
  return str.replaceAll(' ', '-').toLowerCase();
}

/**
 * Format timestamp to 'Month Dayth, Year'
 * @param {string} timestamp - ISO 8601 timestamp
 * @returns {string} - Formatted date
 */
export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const getOrdinal = (n) => {
    const s = ['th', 'st', 'nd', 'rd'],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  return `${month} ${getOrdinal(day)}, ${year}`;
}
/**
 * Set a URL param, if the value is falsey it will remove the URL param
 * Only sets if the value is new.
 * @param {string} newKey - query param key
 * @param {string | null} newValue - query param string
 */

export function setUrlParam(newKey, newValue) {
  const queryParams = parseQueryString(window.location.search);
  if (queryParams[newKey] === newValue) return;
  queryParams[newKey] = newValue;

  const newQueryString = Object.entries(queryParams).reduce((acc, entry, i) => {
    const [key, value] = entry;
    if (!value) return acc;

    const encodedVal = encodeURIComponent(value);
    const queryPart =
      acc.length !== 0 ? `&${key}=${encodedVal}` : `?${key}=${encodedVal}`;
    return acc.concat(queryPart);
  }, '');

  const [querylessUrl] = window.location.href.split('?');

  // Set URL without reloading
  window.history.replaceState(null, '', querylessUrl.concat(newQueryString));
}
