import {faWarning} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import {View, Text} from 'react-native';
import Popover from 'react-native-popover-view/dist/Popover';

import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import {useCart} from '../../context/CartContext';
import {useTheme} from '../../context/ThemeContext';

function ResetButton({disabled, ...props}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <EtherButton style={style.resetCartButton} disabled={disabled} {...props}>
      <Text style={style.resetCartButtonText}>Reset Cart</Text>
    </EtherButton>
  );
}

export default function ResetCart() {
  const [showPopover, setShowPopover] = useState(false);
  const {style, values} = useTheme(getThemedStyles);
  const {cart, clearCart} = useCart();
  const cartEmpty = cart.length === 0;

  return (
    <>
      <ResetButton
        disabled={cartEmpty}
        onPress={cartEmpty ? () => {} : () => setShowPopover(true)}
      />
      <Popover
        isVisible={showPopover}
        onRequestClose={() => setShowPopover(false)}
        popoverStyle={style.background}
      >
        <View style={style.modal}>
          <FaIcon
            icon={faWarning}
            size={42}
            color={values.RED}
            style={{marginBottom: 30}}
          />
          <Text style={style.text}>
            Are you sure you want to reset your cart?
          </Text>
          <View style={{flexDirection: 'row'}}>
            <EtherButton
              style={[
                style.button,
                {backgroundColor: values.RED, borderColor: values.DARK},
              ]}
              onPress={() => {
                clearCart();
                setShowPopover(false);
              }}
            >
              <Text style={style.buttonText}>I'm sure</Text>
            </EtherButton>
            <EtherButton
              onPress={() => {
                setShowPopover(false);
              }}
              style={style.button}
            >
              <Text style={style.buttonText}>Cancel</Text>
            </EtherButton>
          </View>
        </View>
      </Popover>
    </>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'absolute',
    borderRadius: 5,
    zIndex: 1,
  },
  button: {
    width: 120,
    height: 40,
    marginHorizontal: 10,
    marginVertical: 20,
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
  },
  modal: {
    alignItems: 'center',
    width: 450,
    backgroundColor: theme.BGFIRST,
    padding: 20,
    justifyContent: 'space-between',
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
  },
  resetCartButton: {
    position: 'absolute',
    width: 150,
    height: 40,
    borderRadius: 5,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
    right: 15,
  },
  resetCartButtonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.subheader,
    color: theme.LIGHT,
  },
  text: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.DARK,
    marginBottom: 20,
  },
});
