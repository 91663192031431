import {func, bool} from 'prop-types';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import toast from 'react-hot-toast';

import EtherDialog from './EtherDialog';
import EtherInput from './EtherInput';
import {useTheme} from '../context/ThemeContext';
import {useAssets} from '../context/assets-context/AssetsContext';
import {STATUS} from '../utils/common/constants';

AddPackModal.proptypes = {
  show: bool.isRequired,
  onHide: bool.isRequired,
  onAddFolder: func.isRequired,
};

export function AddPackModal({show, onHide}) {
  const {createPack} = useAssets();
  const [packName, setPackName] = useState('');
  const [status, setStatus] = useState(STATUS.IDLE);
  const inputRef = useRef(null);
  const {style} = useTheme(getThemedStyles);

  useEffect(() => {
    if (show && inputRef.current) {
      // Wait for show animation to complete
      setTimeout(() => inputRef.current.focus(), 300);
    }
  }, [show]);

  const handleHide = useCallback(
    (...args) => {
      setPackName('');
      onHide(...args);
    },
    [onHide],
  );

  function onPressAddMore() {
    // Packs need a name for creation
    if (!packName?.length) {
      toast.error('Please enter a name for your pack');
      return;
    }

    setStatus(STATUS.BUSY);
    createPack(packName)
      .then(() => setPackName(''))
      .finally(() => setStatus(STATUS.IDLE));
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const onPressAdd = useCallback(() => {
    // Packs need a name for creation
    if (!packName?.length) {
      toast.error('Please enter a name for your pack');
      return;
    }

    setStatus(STATUS.BUSY);
    createPack(packName)
      .then(() => {
        onHide();
        setPackName('');
      })
      .finally(() => setStatus(STATUS.IDLE));
  }, [packName, createPack, onHide]);

  useEffect(() => {
    function downHandler({key}) {
      if (key === 'Escape') {
        handleHide();
      }
      if (key === 'Enter') {
        onPressAdd();
      }
    }

    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [handleHide, onPressAdd]);

  return (
    <EtherDialog
      header="Add Pack"
      controls={[
        {
          text: 'Cancel',
          disabled: status === STATUS.BUSY,
          onPress: () => onHide(),
        },
        {
          text: 'Add',
          onPress: onPressAdd,
          status,
          variant: 'primary',
        },
        {
          text: 'Add More',
          onPress: onPressAddMore,
          status,
          variant: 'primary',
        },
      ]}
      onClose={onHide}
      show={show}
      style={style.dialog}
    >
      <EtherInput
        title="Pack Name"
        placeholder="Act 1"
        ref={inputRef}
        value={packName}
        onChangeText={setPackName}
        style={{marginBottom: 12}}
      />
    </EtherDialog>
  );
}

export const getThemedStyles = (theme, fontSize) => ({
  addButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    width: 80,
    marginLeft: 15,
  },
  addMoreButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    marginLeft: 15,
    width: 112,
  },
  addText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
    color: theme.LIGHT,
  },
  backdrop: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.BLACKGROUND,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    width: 80,
  },
  closeText: {
    fontSize: fontSize.legal,
    color: theme.FIRST,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  dialog: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    width: 380,
    padding: 20,
    cursor: 'default',
  },
  input: {
    width: '90%',
  },
  modalRow: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});
