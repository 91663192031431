import React from 'react';
import {View, Text} from 'react-native';

import EtherButton, {TextColor} from '../../components/EtherButton';
import EtherMediaLogo from '../../components/EtherMediaLogoSVG';
import {useOperator} from '../../context/OperatorContext';
import {useTheme} from '../../context/ThemeContext';

export default function Authenticate({navigation}) {
  const {navigate} = navigation;
  const {style, values} = useTheme(getThemedStyles);
  const {operatorData} = useOperator();

  function authenticate() {
    const token = localStorage.token;
    const url = `ether-sync:///authenticate?token=${token}`;

    const a = document.createElement('a');
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  if (!operatorData) {
    return (
      <View style={style.container}>
        <View style={style.logo}>
          <EtherMediaLogo color={values.FIRST} />
        </View>
        <Text>
          Please log in to your operator account first, then return here.
        </Text>
        <EtherButton
          text="Log In"
          textColor={TextColor.LIGHT}
          onPress={() => navigate('Log In')}
          style={style.logInButton}
        />
      </View>
    );
  }

  return (
    <View style={style.container}>
      <View style={style.logo}>
        <EtherMediaLogo color={values.FIRST} />
      </View>
      <Text style={style.promptText}>
        You are logged in as {operatorData?.identification?.orgname}. To use
        this account with EtherSync, press the button below.
      </Text>
      <EtherButton
        text={`Authenticate as ${operatorData?.identification?.orgname}`}
        textColor={TextColor.LIGHT}
        style={style.logInButton}
        onPress={authenticate}
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 500,
    marginTop: 40,
    height: 640,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 20,
  },
  logInButton: {
    marginTop: 40,
    width: '100%',
  },
  logo: {
    justifyContent: 'center',
    alignSelf: 'center',
    height: 140,
    width: '90%',
  },
  promptText: {
    fontSize: fontSize.body,
  },
});
