import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {useIsFocused} from '@react-navigation/native';
import React from 'react';
import {Text, View, FlatList, Image} from 'react-native';

import EtherButton from '../../components/EtherButton';
import IdleTimer from '../../components/IdleTimer';
import Payment from '../../components/Payment';
import {useCart} from '../../context/CartContext';
import {useTheme} from '../../context/ThemeContext';
import {ellipsify} from '../../utils/common/funcs';
import {createCart} from '../../utils/common/orders';

function PackPreview({lineItem}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.previewContainer}>
      <Text style={style.previewText}>{ellipsify(lineItem.name, 18)}</Text>
      <Image
        style={style.preview}
        source={{uri: lineItem.assets[0]?.thumb?.url}}
      />
    </View>
  );
}

function EmptyCartMessage() {
  const {style} = useTheme(getThemedStyles);
  return (
    <View style={style.emptyMessageContainer}>
      <Text style={[style.emptyMessage, {paddingBottom: 10}]}>
        Your cart is empty.
      </Text>
      <Text style={[style.emptyMessage, {fontSize: 20}]}>
        Please go back and add items to your cart to continue with your
        purchase.
      </Text>
    </View>
  );
}

export default function Cart({navigation}) {
  const {cart, updateOrderId, changeCartURL} = useCart();
  const {style, values} = useTheme(getThemedStyles);
  const isCartEmpty = cart.length === 0;
  const isFocused = useIsFocused();
  const {clearCart} = useCart();

  function onCheckout() {
    changeCartURL(null);
    const currentEvent = localStorage.getItem('eventName');
    const storeSlug = localStorage.getItem('storeSlug');
    createCart(cart, currentEvent, storeSlug).then(({_id}) =>
      updateOrderId(_id),
    );
    navigation.navigate('Checkout Express');
  }

  function goBack() {
    clearCart();
    navigation.navigate('Kiosk');
  }

  return (
    <IdleTimer onTimeout={goBack} active={isFocused}>
      <View style={style.mainContainer}>
        <View style={style.miniGallery}>
          <FlatList
            data={cart}
            numColumns={3}
            renderItem={({item}) => <PackPreview lineItem={item} />}
            keyExtractor={(item) => item.name}
            ListEmptyComponent={EmptyCartMessage}
          />
        </View>
        <View style={style.infoSection}>
          <View style={style.pricingSection}>
            <Payment />
          </View>
          <View style={style.buttonRow}>
            <EtherButton
              style={style.cancel}
              icon={faAngleLeft}
              iconColor={values.LIGHT}
              iconSize={35}
              onPress={() => window.history.go(-1)}
            />
            <EtherButton
              disabled={isCartEmpty}
              style={style.checkout}
              text="Checkout"
              textStyle={style.checkoutText}
              onPress={onCheckout}
            />
          </View>
        </View>
      </View>
    </IdleTimer>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonRow: {
    marginTop: 20,
    width: '100%',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  cancel: {
    width: 60,
    height: 60,
    paddingTop: 10,
    borderWidth: 3,
  },
  checkout: {
    flex: 1,
    height: 60,
    marginLeft: 10,
    borderWidth: 3,
  },
  checkoutText: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  emptyMessage: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
    textAlign: 'center',
  },
  emptyMessageContainer: {
    justifyContent: 'center',
    height: 420,
    margin: 40,
  },
  infoSection: {
    height: '100%',
    flex: 1,
    marginLeft: 15,
  },
  mainContainer: {
    backgroundColor: theme.BGSECOND,
    flexDirection: 'row',
    padding: 20,
    height: '100%',
    width: '100%',
  },
  miniGallery: {
    backgroundColor: theme.BGFIRST,
    borderWidth: 3,
    borderColor: theme.FIRST,
    borderRadius: 10,
    width: '60%',
    height: '100%',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  preview: {
    height: 175,
    width: 175,
    borderRadius: 10,
    backgroundColor: theme.SECOND,
  },
  previewContainer: {
    paddingHorizontal: 10,
    marginTop: 10,
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  previewText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.FIRST,
    marginBottom: 10,
  },
  pricingSection: {
    backgroundColor: theme.BGFIRST,
    borderWidth: 3,
    borderColor: theme.FIRST,
    flex: 1,
    borderRadius: 10,
  },
});
