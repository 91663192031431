/* A context for managing dropdown menus
 * Usage: <EtherButton dataSet={{dropdownId: myThing}}>Open Menu</EtherButton>
 * Listens for clicks on app root, manages open dropdowns and closes them
 *
 * Important: Click target's child elements need pointer-events: none;
 * Otherwise, the click events won't recieve the data-dropdown-id value.
 *
 * See: AdminGalleryTile.web.js and EventTile.web.js
 */

import React, {useContext, useEffect, useState} from 'react';

const DropdownContext = React.createContext();

function DropdownProvider({children}) {
  const [dropdownId, setDropdownId] = useState(null);

  // Set up click handler for dropdown buttons
  useEffect(() => {
    function clickHandler(e) {
      const menuId = e.target?.dataset?.dropdownId;
      const isMenu = menuId && menuId?.length;
      if (isMenu && menuId !== dropdownId) {
        setDropdownId(menuId);
      } else {
        setDropdownId(null);
      }
    }

    const appRoot = document.getElementById('root');
    appRoot.addEventListener('click', clickHandler);
    return () => appRoot.removeEventListener('click', clickHandler);
  }, [dropdownId, setDropdownId]);

  return (
    <DropdownContext.Provider value={{dropdownId, setDropdownId}}>
      {children}
    </DropdownContext.Provider>
  );
}

function useDropdown() {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error('useDropdown must be used within a DropdownProvider');
  }

  return context;
}

export {useDropdown as default, DropdownProvider};
