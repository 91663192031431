import React, {useContext, useState} from 'react';
import toast from 'react-hot-toast';

import etherFetch from '../utils/ether-fetch/etherFetch.js';

const CartContext = React.createContext();

export function CartProvider({children}) {
  const [cart, setCart] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [cartURL, setCartURL] = useState(null);

  function updateOrderId(newId) {
    setOrderId(newId);
    setCartURL(`http://${window.location.host}/checkout?cart=${newId}`);
  }

  function priceCart(newCart) {
    return etherFetch('/cart/price-cart', {
      method: 'POST',
      body: {
        cart: newCart,
      },
    })
      .then((res) => res.json())
      .catch(() => {
        toast.error('Failed to load order. Please contact support.');
      });
  }

  async function addItem(newItem) {
    const unique = !cart.some(
      ({name, event}) => newItem.name === name && newItem.event === event,
    );
    if (unique) {
      const newCart = await priceCart([...cart, newItem]);
      toast.success(`${newItem.name} added to your Cart!`);
      setCart(newCart);
    } else {
      toast.error('Item already in cart');
    }
  }

  /**
   * Remove pack with the provided name from the cart
   *
   * @param {string} name - Name of the pack to be removed
   */
  function removeItem(name) {
    setCart(cart.filter((item) => item.name !== name));
  }

  /**
   * Clear cart and show a message
   *
   * @param {string} message - Message to display when cart is cleared
   */
  function clearCart(message = 'Cart has been cleared') {
    toast.success(message);
    setCart([]);
  }

  function setItems(value) {
    setCart(value);
  }

  function toggleSelect(item) {
    const itemIndex = cart.findIndex((itm) => itm.name === item.name);

    if (itemIndex >= 0) {
      const newCart = [...cart];
      newCart.splice(itemIndex, 1);
      setItems(newCart);
    } else {
      addItem(item);
    }
  }

  function changeCartURL(url) {
    setCartURL(url);
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addItem,
        removeItem,
        setItems,
        toggleSelect,
        clearCart,
        orderId,
        updateOrderId,
        cartURL,
        changeCartURL,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

function useCart() {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }

  return context;
}

export {useCart};
export default CartContext;
