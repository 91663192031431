import {faUser, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {useIsFocused} from '@react-navigation/native';
import React, {useReducer, useState} from 'react';
import {Text, View, LogBox} from 'react-native';

import reducer, {blueprint} from './reducer';
import EtherButton, {TextColor} from '../../components/EtherButton';
import IdleTimer from '../../components/IdleTimer';
import EtherInput from '../../components/OrderInput';
import {useCart} from '../../context/CartContext';
import {useTheme} from '../../context/ThemeContext';
import {sendCartEmail} from '../../utils/common/email';
import {sendCartText} from '../../utils/common/text';

//This line ignores a warning that is irrelevant to our use case.
//SetTimeout won't work if the app is minimized. They advise
//against using long timeouts. This app wont ever be minimized.
LogBox.ignoreLogs(['Setting a timer']);

export default function Order({navigation}) {
  const [state, dispatch] = useReducer(reducer, blueprint);
  const [allDone, setAllDone] = useState(false);
  const {clearCart, cartURL} = useCart();
  const {style, values} = useTheme(getThemedStyles);
  const emailAndPhone = state.validEmail && state.validPhone;
  const validName = state.firstName && state.lastName;
  const justEmail = state.validEmail && state.phoneNumber === '';
  const justPhone = state.validPhone && state.email === '';
  const nextEnabled = (emailAndPhone || justEmail || justPhone) && validName;
  const isFocused = useIsFocused();

  function sendOrder() {
    if (state.validEmail) {
      sendCartEmail(cartURL, state.email, state.firstName).then((res) =>
        console.log(res),
      );
    }
    if (state.validPhone) {
      sendCartText(cartURL, state.phoneNumber, state.firstName).then((res) =>
        console.log(res),
      );
    }
    setAllDone(true);
    clearCart('Order sent, continue on your phone.', 8000, style.theme.green);
    setTimeout(() => {
      window.location = localStorage.getItem('kioskUrl');
    }, 8300);
  }

  function leaveEarly() {
    clearCart();
    window.location = localStorage.getItem('kioskUrl');
  }

  return (
    <IdleTimer onTimeout={leaveEarly} active={isFocused}>
      <View style={style.main}>
        {allDone ? (
          <View style={style.headerContainer}>
            <Text style={style.header}>Order Sent!</Text>
            <Text style={style.subHeader}>
              Use the link we sent you to complete your order.
            </Text>
          </View>
        ) : (
          <>
            <View style={style.headerContainer}>
              <Text style={style.header}>Almost there!</Text>
              <Text style={style.subHeader}>
                Please enter your information below to continue
              </Text>
            </View>
            <View style={style.inputContainer}>
              <EtherInput
                icon={faUser}
                title="First Name"
                placeholder={state.placeholders.firstName}
                placeholderTextColor={values.BLUR}
                value={state.firstName}
                onChange={(firstName) =>
                  dispatch({type: 'set.firstName', value: firstName})
                }
              />
              <EtherInput
                icon={faUser}
                title="Last Name"
                placeholder="Last Name"
                placeholderTextColor={values.BLUR}
                value={state.lastName}
                onChange={(lastName) =>
                  dispatch({type: 'set.lastName', value: lastName})
                }
              />
            </View>
            <View style={style.inputContainer}>
              <EtherInput
                icon={faEnvelope}
                inputStyle={state.validEmail ? style.settingsInputValid : null}
                title="Email"
                placeholder={state.placeholders.email}
                placeholderTextColor={values.BLUR}
                value={state.email}
                keyboardType="email-address"
                onChange={(email) =>
                  dispatch({type: 'set.email', value: email})
                }
              />
              <EtherInput
                icon={faPhone}
                inputStyle={state.validPhone ? style.settingsInputValid : null}
                title="Phone Number"
                keyboardType="number-pad"
                maxLength={14}
                placeholder={state.placeholders.phoneNumber}
                placeholderTextColor={values.BLUR}
                value={state.phoneNumber}
                onChange={(phone) =>
                  dispatch({
                    type: 'set.phoneNumber',
                    value: phone,
                  })
                }
              />
            </View>
            <View style={style.buttonContainer}>
              <EtherButton
                style={[style.wideButton, style.backButton]}
                onPress={() => window.history.go(-1)}
                text="Back"
                textColor={TextColor.DARK}
              />
              <EtherButton
                style={[style.wideButton, nextEnabled ? null : style.buttonOff]}
                onPress={sendOrder}
                text="Submit"
                textColor={nextEnabled ? TextColor.LIGHT : TextColor.DARK}
                disabled={!nextEnabled}
              />
            </View>
            <Text style={style.disclaimer}>
              Submitting this form means you agree to our Terms of Service and
              Privacy Policy.
            </Text>
          </>
        )}
      </View>
    </IdleTimer>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  backButton: {
    backgroundColor: theme.LIGHT,
    borderColor: theme.FIRST,
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 20,
  },
  buttonOff: {
    backgroundColor: theme.DISABLED,
    borderColor: theme.DISABLEDBORDER,
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.subheader,
    color: theme.LIGHT,
    textAlign: 'center',
  },
  buttonTextOff: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.subheader,
    color: theme.DARK,
    textAlign: 'center',
  },
  disclaimer: {
    color: theme.DARK,
  },
  header: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 50,
    color: theme.FIRST,
    textAlign: 'center',
  },
  inputContainer: {
    width: 700,
    justifyContent: 'space-between',
    paddingVertical: 20,
    flexDirection: 'row',
  },
  main: {
    alignItems: 'center',
    padding: 40,
  },
  settingsInputValid: {
    backgroundColor: theme.BGSECOND,
    borderColor: theme.GREEN,
  },
  subHeader: {
    fontStyle: 'italic',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.SECOND,
    textAlign: 'center',
    marginBottom: 40,
  },
  theme: {
    green: theme.GREEN,
  },
  wideButton: {
    width: 120,
    marginHorizontal: 10,
  },
});
