import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {oneOfType, arrayOf, object, number, string} from 'prop-types';
import React, {useState} from 'react';
import {View, FlatList, Image, TouchableOpacity, Text} from 'react-native';

import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {useIsMobile} from '../utils/common/hooks';

DownloadSummary.propTypes = {
  style: oneOfType([object, number]),
  previewThumbs: arrayOf(string).isRequired,
};
export default function DownloadSummary({style: overrides, previewThumbs}) {
  const {isMobile} = useIsMobile();
  const {style} = useTheme(getThemedStyles);
  const [extended, setExtended] = useState(false);
  const columns = isMobile ? 2 : 4;

  return (
    <View style={overrides}>
      <View style={style.imageZone}>
        <FlatList
          data={extended ? previewThumbs : previewThumbs.slice(0, 4)}
          renderItem={({item}) => <Image style={style.image} source={item} />}
          numColumns={columns}
          key={columns}
        />
        {previewThumbs.length > 4 ? (
          <View style={style.moreContainer}>
            <TouchableOpacity
              onPress={() => setExtended(!extended)}
              style={style.moreButton}
            >
              <FaIcon icon={extended ? faMinus : faPlus} />
              <Text>
                {extended ? 'show less' : `${previewThumbs.length - 4} more`}
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  image: {
    height: 180,
    width: 180,
    marginRight: 10,
    marginTop: 10,
    borderRadius: 10,
    mobile: {
      height: 160,
      width: 160,
    },
  },
  imageZone: {
    alignItems: 'center',
  },
  moreButton: {
    alignItems: 'center',
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
  moreContainer: {
    alignItems: 'center',
    height: 80,
    padding: 20,
    justifyContent: 'space-between',
  },
});
