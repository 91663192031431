import {number, object, shape} from 'prop-types';
import React from 'react';
import {View, FlatList, Text} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import {centsToDollars, ellipsify} from '../utils/common/funcs';

function formatCents(cents) {
  const dollars = (cents / 100).toFixed(2);
  return dollars;
}

function calculateSubtotal(lineItems) {
  let subtotal = 0;
  lineItems.forEach((obj) => {
    subtotal = subtotal + obj.price;
  });
  return formatCents(subtotal);
}

Order.propTypes = {
  orderData: shape({price: number, pack: object}),
};
function Order({cartItem}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.order}>
      <View style={style.cellPack}>
        <Text style={style.text}>{ellipsify(cartItem.pack.name, 12)}</Text>
      </View>
      <View style={style.cellPrice}>
        <Text style={style.text}>{centsToDollars(cartItem.price)}</Text>
      </View>
    </View>
  );
}

PaymentWeb.propTypes = {
  cart: object.isRequired,
};
export default function PaymentWeb({cart}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <>
      <View style={style.main}>
        <View style={style.topBar}>
          <View style={style.cellPack}>
            <Text style={style.headerText}>Pack</Text>
          </View>
          <View style={style.cellPrice}>
            <Text style={style.headerText}>Price</Text>
          </View>
        </View>
        <FlatList
          data={cart.items}
          renderItem={({item}) => <Order cartItem={item} />}
          numColumns={1}
          keyExtractor={(item) => item.name}
        />
      </View>
      <View style={style.subTotal}>
        <Text style={style.subTotalText}>Subtotal: </Text>
        <Text style={style.subTotalText}>${calculateSubtotal(cart.items)}</Text>
      </View>
    </>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  cellPack: {
    flex: 1,
  },
  cellPrice: {
    flex: 1,
  },
  headerText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    alignSelf: 'center',
  },
  main: {
    width: '100%',
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 10,
    overflow: 'hidden',
  },
  order: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 8,
  },
  subTotal: {
    padding: 20,
    flexDirection: 'column',
  },
  subTotalText: {
    alignSelf: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  text: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
  },
  topBar: {
    backgroundColor: theme.SECOND,
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderColor: theme.FIRST,
    borderBottomWidth: 2,
    padding: 8,
  },
});
