/*eslint import/no-unresolved: [2, { ignore: ['swiper/*'] }]*/

import {
  faCheckCircle,
  faCircleExclamation,
  faCookieBite,
  faDatabase,
  faRotate,
  faTabletScreenButton,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {Text, View} from 'react-native';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {useIsMobile} from '../../utils/common/hooks';

function Item({icon, text, color}) {
  const {style} = useTheme(useThemedStyles);

  return (
    <View style={style.item}>
      <FaIcon icon={icon} size={26} color={color} />
      <Text style={style.itemText}>{text}</Text>
    </View>
  );
}

export function Pricing({navigateContact}) {
  const {style, values} = useTheme(useThemedStyles);
  // const [isYearly, setIsYearly] = useState(false);
  const {isMobile} = useIsMobile();

  // function ToggleSwitch() {
  //   setIsYearly(!isYearly);
  // }

  return (
    <>
      <View style={style.pricing}>
        <Text style={style.rolloutTitle}>Available Plans</Text>
        {/* TO BE USED WHEN MORE THAN ONE CARD */}
        {/* <Switch
          trackColor={{false: '#767577', true: values.GREEN}}
          thumbColor={values.BGFIRST}
          activeThumbColor={values.BGFIRST}
          onValueChange={ToggleSwitch}
          value={isYearly}
          style={{marginBottom: 15}}
        />
        <Text
          style={[style.customText, {color: values.BGFIRST, marginBottom: 40}]}
        >
          {isYearly ? 'yearly plans' : 'monthly plans'}
        </Text> */}
        {!isMobile ? (
          <View style={style.cards}>
            <View style={style.card}>
              <View style={style.cardHeader}>
                <EtherButton
                  text="Contact Us"
                  textColor={values.LIGHT}
                  onPress={navigateContact}
                />
                <Text style={[style.header, {fontSize: 20, marginTop: 10}]}>
                  Get a quote
                </Text>
                <Text style={style.tagline}>
                  Receive full access to the EtherMedia platform.
                </Text>
              </View>
              <View style={style.separator} />
              <View style={style.cardBottom}>
                <Item
                  icon={faCircleExclamation}
                  color={values.FIRST}
                  text="Flexible Revenue Share"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Frictionless point of sale"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Digital Asset Delivery"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Cloud Management"
                />
                <Item
                  icon={faDatabase}
                  color={values.FIRST}
                  text="Terabytes of Storage"
                />
                <Item
                  icon={faRotate}
                  color={values.FIRST}
                  text="Desktop Sync App"
                />
                <Item
                  icon={faTabletScreenButton}
                  color={values.FIRST}
                  text="Up to 10 Active Kiosks Licenses"
                />
                {/* {isYearly ? ( */}
                <Item
                  icon={faCookieBite}
                  color={values.FIRST}
                  text="Free Cookie (if possible)"
                />
                {/* ) : null} */}
              </View>
            </View>
          </View>
        ) : (
          <Swiper
            slidesPerView="auto"
            centeredSlides
            rewind
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="homeSwiper"
          >
            <SwiperSlide style={style.card}>
              <View style={style.cardHeader}>
                <EtherButton
                  text="Contact Us"
                  textColor={values.LIGHT}
                  onPress={navigateContact}
                />
                <Text style={[style.header, {fontSize: 20, marginTop: 10}]}>
                  Get a quote
                </Text>
                <Text style={style.tagline}>
                  Receive full access to the EtherMedia platform.
                </Text>
              </View>
              <View style={style.separator} />
              <View style={style.cardBottom}>
                <Item
                  icon={faCircleExclamation}
                  color={values.FIRST}
                  text="Flexible Revenue Share"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Frictionless point of sale"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Digital Asset Delivery"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Cloud Management"
                />
                <Item
                  icon={faDatabase}
                  color={values.FIRST}
                  text="Terabytes of Storage"
                />
                <Item
                  icon={faRotate}
                  color={values.FIRST}
                  text="Desktop Sync App"
                />
                <Item
                  icon={faTabletScreenButton}
                  color={values.FIRST}
                  text="Up to 10 Active Kiosks Licenses"
                />
                {/* {isYearly ? ( */}
                <Item
                  icon={faCookieBite}
                  color={values.FIRST}
                  text="Free Cookie (if possible)"
                />
                {/* ) : null} */}
              </View>
            </SwiperSlide>
          </Swiper>
        )}
      </View>
      <View>
        <Text style={style.bottomText}>
          Closed beta in progress!
          <br />
          Get in touch with us for exclusive access
        </Text>
      </View>
    </>
  );
}

const useThemedStyles = (theme, fontSize) => ({
  bottomText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 16,
    color: theme.LIGHT,
    marginBottom: 30,
    textAlign: 'center',
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
    mobile: {
      fontSize: 18,
    },
  },
  billType: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 10,
    color: '#7a7a7a',
    paddingVertical: 5,
    marginBottom: 20,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.BGFIRST,
    paddingVertical: 20,
    padding: 30,
    borderRadius: 10,
    width: 300,
    marginBottom: 40,
    marginHorizontal: 20,
    mobile: {
      marginHorizontal: null,
      width: 200,
      maxWidth: 200,
    },
  },
  cardHeader: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  cards: {
    flexDirection: 'row',
  },
  header: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
    marginBottom: 5,
  },
  itemText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
    marginLeft: 10,
  },
  customText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
    textAlign: 'center',
    paddingVertical: 10,
  },
  priceNumber: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 36,
    color: theme.DARK,
    marginBottom: -8,
  },
  pricePer: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: '#7a7a7a',
  },
  pricing: {
    alignItems: 'center',
    marginTop: 20,
  },
  cardBottom: {
    flex: 1,
    justifyContent: 'center ',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  rolloutTitle: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.starHeader,
    color: theme.LIGHT,
    marginBottom: 60,
    mobile: {
      marginBottom: 40,
      fontSize: fontSize.planetHeader,
      textAlign: 'center',
    },
  },
  separator: {
    backgroundColor: theme.DISABLED,
    width: '100%',
    height: 2,
    marginVertical: 40,
  },
  tagline: {
    width: 240,
    fontFamily: 'NotoSans_Regular',
    fontSize: 13,
    textAlign: 'center',
    color: '#7a7a7a',
    mobile: {
      width: 200,
    },
  },
});
