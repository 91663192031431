import LogRocket from 'logrocket';
import React, {useState} from 'react';
import {View, Text} from 'react-native';

import EtherButton, {TextColor} from '../../components/EtherButton';
import EtherInput from '../../components/EtherInput';
import EtherMediaLogo from '../../components/EtherMediaLogoSVG';
import {useOperator} from '../../context/OperatorContext';
import {useTheme} from '../../context/ThemeContext';
import {useAssets} from '../../context/assets-context/AssetsContext';
import {STATUS} from '../../utils/common/constants';
import {isProd} from '../../utils/common/funcs';
import {logIn} from '../../utils/common/login';

export default function LogIn({navigation}) {
  const {navigate} = navigation;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(STATUS.IDLE);
  const {changeOperator} = useOperator();
  const {trySocketConnection} = useAssets();
  const {style, values} = useTheme(getThemedStyles);

  function handleLogin() {
    if (status === STATUS.IDLE) {
      setStatus(STATUS.BUSY);
      logIn(username, password)
        .then((data) => {
          localStorage.username = data.username;
          localStorage.token = data.token;
          localStorage.userId = data.userId;
          trySocketConnection(data.userId);

          if (isProd()) {
            LogRocket.identify(data.userId, {
              name: data.username,
              email: data.email,
            });
          }

          changeOperator(data.username);
          navigate('Event Manager');
        })
        .catch((error) => {
          console.error('Error:', error);
          setStatus(STATUS.INVALID);
        })
        .finally(() => setTimeout(() => setStatus(STATUS.IDLE), 3000));
    }
  }

  return (
    <View style={style.container}>
      <View style={style.logo}>
        <EtherMediaLogo color={values.FIRST} />
      </View>
      <EtherInput
        title="Username"
        value={username}
        onChangeText={setUsername}
        onSubmitEditing={handleLogin}
        status={STATUS.IDLE}
      />
      <EtherInput
        title="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
        onSubmitEditing={handleLogin}
        status={STATUS.IDLE}
      />
      <EtherButton
        text="Log In"
        textColor={TextColor.LIGHT}
        onPress={handleLogin}
        style={style.logInButton}
        status={status}
      />
      <EtherButton
        basic
        style={style.forgotPassword}
        onPress={() => navigate('Reset Password')}
        text="Forgot your password"
        textStyle={style.forgotPasswordText}
      />
      <View style={style.separator}>
        <View style={style.emptyView} />
        <Text style={style.or}>or</Text>
        <View style={style.emptyView} />
      </View>
      <View style={style.signupView}>
        <EtherButton
          text="Sign Up"
          textColor={TextColor.BLURPLE}
          style={style.signupButton}
          onPress={() => navigate('Sign Up')}
          status={STATUS.IDLE}
        />
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 500,
    marginTop: 40,
    height: 640,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 20,
  },
  emptyView: {
    borderBottomWidth: 2,
    width: '40%',
    borderBottomColor: theme.SECOND,
  },
  forgotPassword: {
    marginTop: 20,
  },
  forgotPasswordText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.FIRST,
  },
  logInButton: {
    marginTop: 40,
    width: '100%',
  },
  logInText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    textAlign: 'center',
  },
  logo: {
    justifyContent: 'center',
    alignSelf: 'center',
    height: 140,
    width: '90%',
  },
  main: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: theme.BGSECOND,
  },
  or: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.SECOND,
  },
  separator: {
    flex: 1,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  signupButton: {
    width: '100%',
    borderColor: theme.FIRST,
    backgroundColor: theme.BGSECOND,
  },
  signupText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.FIRST,
    textAlign: 'center',
  },
  signupView: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
});
