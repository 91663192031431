import {faImage, faVideo} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import {View, Text, Image, FlatList} from 'react-native';

import LoadingStore from './LoadingStore';
import FlyerNotFound from '../../assets/flyer_not_found.png';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import PoweredBy from '../../components/PoweredBy';
import {useTheme} from '../../context/ThemeContext';
import {formatTimestamp} from '../../utils/common/funcs';
import {useCalcTileColumns} from '../../utils/common/hooks';
import etherFetch from '../../utils/ether-fetch/etherFetch';

function Tile({item, navigation, storeSlug, eventId}) {
  const {style, values} = useTheme(getThemedStyles);
  return (
    <EtherButton
      basic
      style={style.tile}
      onPress={() => {
        navigation.navigate('AssetStorefront', {
          storeSlug,
          eventId,
          packName: item.name,
        });
      }}
    >
      <View style={style.tileTextContainer}>
        <Text style={style.tileText}>{item.name}</Text>
        <View style={style.tileInformation}>
          <FaIcon size={28} color={values.LIGHT} icon={faImage} />
          <Text style={style.tileInformationText}>{item.imageCount}</Text>
          <FaIcon size={28} color={values.LIGHT} icon={faVideo} />
          <Text style={style.tileInformationText}>{item.videoCount}</Text>
        </View>
      </View>
      <Image source={item.favorite} style={style.tileImage} />
    </EtherButton>
  );
}

export default function StorePackView({route, navigation}) {
  const {style} = useTheme(getThemedStyles);
  const [packs, setPacks] = useState(null);
  const [storeInfo, setStoreInfo] = useState(null);
  const [eventInfo, setEventInfo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const {storeSlug, eventId} = route.params;
  const numColumns = Math.min(useCalcTileColumns(490), 3);

  useEffect(() => {
    async function fetchPacks() {
      if (storeSlug && eventId) {
        setLoaded(false);
      }
      try {
        const res = await etherFetch('/store/event-with-packs', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            storeSlug,
            eventId,
          },
        });

        const {packs: fetchedPacks, store, event} = await res.json();
        setStoreInfo(store);
        setPacks(fetchedPacks);
        setEventInfo(event);
      } catch (error) {
        console.error('Error fetching packs:', error);
      } finally {
        setLoaded(true);
      }
    }
    fetchPacks();
  }, [storeSlug, eventId]);

  if (!loaded) {
    return <LoadingStore />;
  }
  return (
    <View style={style.container}>
      {storeInfo.logo ? (
        <Image
          style={style.userLogo}
          resizeMode="contain"
          source={storeInfo.logo}
        />
      ) : null}
      <Image
        style={style.banner}
        source={
          eventInfo?.flyer?.url ? {uri: eventInfo.flyer.url} : FlyerNotFound
        }
      />
      <Text style={style.subheader}>
        {eventInfo.location} - {formatTimestamp(eventInfo.createdAt)}
      </Text>
      <Text style={style.header}>{eventInfo.name}</Text>

      <View style={style.packContainer}>
        <FlatList
          contentContainerStyle={style.tiles}
          data={packs}
          numColumns={numColumns}
          key={numColumns}
          renderItem={({item}) => (
            <Tile
              storeSlug={storeSlug}
              navigation={navigation}
              item={item}
              eventId={eventId}
            />
          )}
          keyExtractor={(item) => item.id.toString()}
        />
      </View>
      <PoweredBy />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  banner: {
    margin: 20,
    maxWidth: 1400,
    minWidth: 400,
    width: '100%',
    height: 320,
    borderRadius: 10,
    marginBottom: 40,
    mobile: {
      height: 240,
    },
  },
  buyButton: {
    marginTop: 20,
    marginBottom: 40,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 20,
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.planetHeader,
    mobile: {
      fontSize: fontSize.header,
    },
  },
  image: {
    flex: 1,
    aspectRatio: 1,
    margin: 2,
  },
  packContainer: {
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 40,
    marginBottom: 40,
  },
  subheader: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.header,
    textAlign: 'center',
    mobile: {
      fontSize: fontSize.bigBody,
    },
  },
  tile: {
    width: 450,
    height: 270,
    borderRadius: 10,
    marginHorizontal: 10,
    marginBottom: 20,
    position: 'relative',
    overflow: 'hidden',
    mobile: {
      width: 300,
    },
  },

  tileImage: {
    zIndex: 0,
    flex: 1,
  },
  tileInformation: {
    flexDirection: 'row',
  },
  tileInformationText: {
    fontFamily: 'NotoSans_Regular',
    justifyContent: 'center',
    fontSize: fontSize.bigBody,
    marginHorizontal: 10,
    color: theme.LIGHT,
  },
  tiles: {
    alignItems: 'center',
  },
  tileText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.subheader,
    color: theme.LIGHT,
    marginBottom: 3,
  },
  tileTextContainer: {
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    paddingVertical: 15,
    justifyContent: 'center',
    background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
  },
  userLogo: {
    margin: 20,
    minWidth: 300,
    maxWidth: 600,
    height: 140,
  },
});
