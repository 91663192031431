import React, {useEffect, useState} from 'react';
import {View, Text, Image, Modal} from 'react-native';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import LoadingStore from './LoadingStore';
import EtherButton from '../../components/EtherButton';
import ImageViewerComponent from '../../components/ImageViewer';
import PoweredBy from '../../components/PoweredBy';
import {useCart} from '../../context/CartContext';
import {useTheme} from '../../context/ThemeContext';
import {formatTimestamp} from '../../utils/common/funcs';
import etherFetch from '../../utils/ether-fetch/etherFetch';

export default function StoreAssetView({route}) {
  const {style} = useTheme(getThemedStyles);
  const [assets, setAssets] = useState([]);
  const [currentPack, setCurrentPack] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [storeInfo, setStoreInfo] = useState(false);
  const [eventInfo, setEventInfo] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const {storeSlug, packName, eventId} = route.params;
  const {addItem} = useCart();

  useEffect(() => {
    async function loadStore() {
      setLoaded(false);

      const infoPms = etherFetch('/store/event-with-packs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {storeSlug, eventId},
      });

      const assetPms = etherFetch('/store/assets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {storeSlug, packName},
      });

      const infoRes = await infoPms;
      const assetRes = await assetPms;
      const {event, store} = await infoRes.json();
      const {assets: fetchedAssets, pack} = await assetRes.json();
      const sortedAssets = [...fetchedAssets].sort((a, b) => {
        if (a.favorite && !b.favorite) return -1;
        if (!a.favorite && b.favorite) return 1;
        return 0;
      });

      setCurrentPack(pack);
      setAssets(sortedAssets);
      setStoreInfo(store);
      setEventInfo(event);
      setLoaded(true);
    }
    if (storeSlug && packName) {
      loadStore();
    }
  }, [storeSlug, packName, eventId]);

  function closeImageViewer() {
    setSelectedIndex(null);
  }

  function addPack() {
    addItem({
      name: currentPack.name,
      packId: currentPack.id,
      event: eventId,
      assets,
    });
  }

  if (!loaded) {
    return <LoadingStore />;
  }
  return (
    <View style={style.container}>
      <Modal visible={!!assets[selectedIndex]} transparent ariaHideApp={false}>
        <ImageViewerComponent
          onClose={closeImageViewer}
          assets={assets}
          currentIndex={selectedIndex}
          setIndex={setSelectedIndex}
        />
      </Modal>
      {storeInfo.logo ? (
        <Image
          style={style.userLogo}
          resizeMode="contain"
          source={storeInfo.logo}
        />
      ) : null}
      <Text style={style.subheader}>
        {eventInfo.location} - {formatTimestamp(eventInfo.createdAt)}
        {'\n'}
        {eventInfo.name}
      </Text>
      <Text style={style.header}>{packName}</Text>
      <EtherButton
        onPress={addPack}
        style={style.buyButton}
        text="Add to Cart"
      />

      <View style={style.imageContainer}>
        <ResponsiveMasonry columnsCountBreakPoints={{500: 1, 780: 2, 1100: 3}}>
          <Masonry gutter="12px">
            {assets.map((asset, index) => (
              <img
                key={asset._id}
                src={asset.thumb.url}
                style={{width: '100%', display: 'block', cursor: 'pointer'}}
                onClick={() => setSelectedIndex(index)}
                alt=""
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </View>
      <PoweredBy />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  banner: {
    margin: 20,
    minWidth: 400,
    width: '100%',
    height: 320,
    borderRadius: 10,
    marginBottom: 40,
    mobile: {
      height: 240,
    },
  },
  buyButton: {
    marginTop: 40,
    marginBottom: 50,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 20,
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.planetHeader,
    mobile: {
      fontSize: fontSize.header,
    },
  },
  image: {
    flex: 1,
    aspectRatio: 1,
    margin: 2,
  },
  imageContainer: {
    width: '100%',
    minWidth: 380,
    paddingHorizontal: '15%',
    marginBottom: 40,
    mobile: {
      paddingHorizontal: 50,
    },
  },
  subheader: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.header,
    textAlign: 'center',
    mobile: {
      fontSize: fontSize.bigBody,
    },
  },
  userLogo: {
    margin: 20,
    minWidth: 300,
    maxWidth: 600,
    height: 140,
  },
});
