import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {func, array, string} from 'prop-types';
import React from 'react';
import {Text} from 'react-native';

import EtherButton from './EtherButton';
import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';

AddSquare.propTypes = {
  style: array.isRequired,
  onPress: func.isRequired,
  text: string,
};

//Event Manager/Admin Gallery tile sized rectangle w a plus icon on it
export default function AddSquare({
  style: overrides,
  onPress = () => {},
  text,
}) {
  const {style, values} = useTheme(getThemedStyles);
  return (
    <EtherButton
      basic
      style={[style.mainContainer, overrides]}
      onPress={onPress}
    >
      {text ? <Text style={style.text}>{text}</Text> : null}
      <FaIcon icon={faPlus} size={36} color={values.FIRST} style={style.icon} />
    </EtherButton>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  mainContainer: {
    borderWidth: 4,
    borderRadius: 15,
    borderStyle: 'dashed',
    borderColor: theme.FIRST,
    height: 240,
    width: 300,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.FIRST,
    textAlign: 'center',
  },
});
