import React from 'react';
import {View, Text, ActivityIndicator} from 'react-native';

import {useTheme} from '../../context/ThemeContext';

export default function LoadingStore() {
  const {style, values} = useTheme(getThemedStyles);
  return (
    <View style={style.empty}>
      <ActivityIndicator size={48} color={values.FIRST} />
      <Text style={style.emptyText}>Loading store...</Text>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  empty: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyText: {
    marginTop: 15,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
});
