import {
  faDashboard,
  faHistory,
  faSignOutAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import {func, string, object} from 'prop-types';
import React, {useRef, useEffect, useState} from 'react';
import {View} from 'react-native';

import EtherButton from './EtherButton';
import {useOperator} from '../context/OperatorContext';
import {useTheme} from '../context/ThemeContext';
import {isDev} from '../utils/common/funcs';

MenuItem.proptypes = {
  title: string.isRequired,
  onPress: func,
  icon: object.isRequired,
};
function MenuItem({title, onPress, icon}) {
  const {style, values} = useTheme(getThemedStyles);
  const [mouseHover, setMouseHover] = useState(null);
  return (
    <EtherButton
      basic
      icon={icon}
      iconSize={24}
      iconColor={mouseHover ? values.LIGHT : values.SECOND}
      iconStyle={{marginLeft: 5}}
      text={title}
      textStyle={[style.buttonText, mouseHover ? style.highlightColor : null]}
      onPress={onPress}
      onMouseOver={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      style={[style.button, mouseHover ? style.buttonHighlighted : null]}
    />
  );
}

function useOutsideAlerter(ref, onHide) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onHide();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onHide]);
}

AccountMenu.proptypes = {
  onPressAccount: func,
  onPressOrders: func,
  onPressLogOut: func,
};
export default function AccountMenu({
  onPressAdminPanel = () => {},
  onPressAccount = () => {},
  onPressOrders = () => {},
  onPressLogOut = () => {},
  onHide = () => {},
}) {
  const {operatorData} = useOperator();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onHide);
  const {style} = useTheme(getThemedStyles);
  return (
    <View style={style.mainContainer} ref={wrapperRef}>
      {isDev() || operatorData?.isAdmin ? (
        <>
          <MenuItem
            icon={faDashboard}
            title="Admin Panel"
            onPress={onPressAdminPanel}
          />
          <View style={style.separator} />
        </>
      ) : null}
      <MenuItem
        icon={faUserCircle}
        title="Manage Account"
        onPress={onPressAccount}
      />
      <MenuItem icon={faHistory} title="Orders" onPress={onPressOrders} />
      <View style={style.separator} />
      <MenuItem icon={faSignOutAlt} title="Log out" onPress={onPressLogOut} />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  button: {
    height: 35,
    marginVertical: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonHighlighted: {
    backgroundColor: theme.SECOND,
  },
  buttonText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
    marginLeft: 10,
  },
  highlightColor: {
    color: theme.LIGHT,
  },
  mainContainer: {
    position: 'absolute',
    width: 200,
    marginTop: 48,
    backgroundColor: theme.BGSECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    right: 0,
    paddingVertical: 5,
  },
  separator: {
    borderColor: theme.FIRST,
    borderWidth: 1,
    marginVertical: 5,
  },
});
