import {node, oneOf, string} from 'prop-types';
import React, {forwardRef, useState} from 'react';
import {Platform, Text, TextInput, View} from 'react-native';

import EtherButton from './EtherButton';
import {useTheme} from '../context/ThemeContext';
import {PLATFORMS, STATUS} from '../utils/common/constants';
import {kebab} from '../utils/common/funcs';

const propTypes = {
  children: node,
  status: oneOf(Object.values(STATUS)),
  description: string,
};

const EtherInput = forwardRef(
  (
    {
      title,
      description,
      status = STATUS.IDLE,
      style: overrides,
      inputStyle: inputOverrides,
      onFocus = () => {},
      onBlur = () => {},
      onPress = () => {},
      btnText = '',
      btnProps = {},
      editable = true,
      money = false,
      ...rest
    },
    ref,
  ) => {
    const {style} = useTheme(getThemedStyles);
    const [focus, setFocus] = useState(false);

    function handleFocus() {
      setFocus(true);
      onFocus();
    }
    function handleBlur() {
      setFocus(false);
      onBlur();
    }

    return (
      <View style={overrides}>
        {title ? (
          <Text style={[style.headerDarkText, style.headerText]}>{title}</Text>
        ) : null}
        <View style={{flexDirection: 'row'}}>
          {money ? (
            <Text style={[style.dollarSign, {color: style.textInput.color}]}>
              $
            </Text>
          ) : null}
          <TextInput
            style={[
              focus ? style.focus : style.blur,
              style.textInput,
              style[status],
              editable ? null : style.disabled,
              inputOverrides,
            ]}
            readOnly={!editable} // editable is deprecated
            placeholderTextColor={style.placeholderText.color}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={ref}
            testID={`input-${kebab(title || 'No title')}`}
            {...rest}
          />
          {onPress && btnText ? (
            <EtherButton
              style={style.button}
              textStyle={style.buttonText}
              text={btnText}
              onPress={onPress}
              {...btnProps}
            />
          ) : null}
        </View>
        {description ? (
          <Text
            style={
              Platform.OS !== PLATFORMS.web
                ? style.descriptionApp
                : style.descriptionWeb
            }
          >
            {description}
          </Text>
        ) : null}
      </View>
    );
  },
);

EtherInput.displayName = 'EtherInput';
EtherInput.propTypes = propTypes;
export default EtherInput;

const getThemedStyles = (theme, fontSize) => ({
  blur: {
    borderColor: theme.BLUR,
  },
  button: {
    marginLeft: 20,
    height: 50,
    flexGrow: 0,
    paddingHorizontal: 20,
  },
  buttonText: {
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  descriptionApp: {
    marginTop: 10,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.regular,
    color: theme.DARK,
  },
  descriptionWeb: {
    marginTop: 5,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.tiny,
    color: theme.DARK,
  },
  disabled: {
    backgroundColor: theme.BGFIRST,
    color: theme.BLUR,
  },
  dollarSign: {
    position: 'absolute',
    top: 12,
    left: 12,
    fontFamily: 'NotoSans_Regular',
    fontStyle: 'bold',
  },
  focus: {
    borderColor: theme.FIRST,
  },
  headerText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    paddingVertical: 10,
  },
  placeholderText: {
    color: theme.BLUR,
  },
  textInput: {
    borderWidth: 2,
    borderRadius: 5,
    paddingHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: '100%',
    backgroundColor: theme.BGSECOND,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    flexShrink: 1,
  },
  [STATUS.IDLE]: {
    backgroundColor: theme.BGSECOND,
    borderColor: theme.FIRST,
  },
  [STATUS.INVALID]: {
    borderColor: theme.RED,
  },
  [STATUS.VALID]: {
    borderColor: theme.GREEN,
  },
});
