export const DEFAULTPACKPRICE = '4599';
export const DEFAULTASSETPRICE = '399';

export const HEADER_HEIGHT = 65;
export const HEADER_HEIGHT_KIOSK = 60;

export const particleCfg = {
  background: {
    color: {
      value: '#4C6CD3', // theme ether.FIRST
    },
  },
  fpsLimit: 30,
  particles: {
    color: {
      value: '#878DEC', // theme ether.SECOND
    },
    move: {
      direction: 'none',
      enable: true,
      outModes: {
        default: 'bounce',
      },
      random: false,
      speed: 3,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        area: 2400,
      },
      value: 15,
    },
    opacity: {
      value: 0.2,
    },
    shape: {
      type: 'circle',
    },
    size: {
      value: {min: 40, max: 60},
    },
  },
  detectRetina: true,
};

export const PLATFORMS = {
  android: 'android',
  ios: 'ios',
  web: 'web',
};

export const SELECT = {
  DEFAULT: 'default',
  SELECTABLE: 'selectable',
  SELECTED: 'selected',
};

export const STATUS = {
  BUSY: 'busy',
  VALID: 'valid',
  INVALID: 'invalid',
  IDLE: 'idle',
};

export const toasterCfg = {
  duration: 10 * 1000,
  style: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 12,
    background: '#E2E2E2', //theme.BGFIRST
    color: '#444444', //theme.DARK
  },
  success: {
    duration: 6000,
    style: {
      fontFamily: 'NotoSans_Regular',
      fontSize: 12,
      background: '#44B149', //theme.GREEN
      color: '#E2E2E2', //theme.LIGHT
    },
  },
  error: {
    duration: 6000,
    style: {
      fontFamily: 'NotoSans_Regular',
      fontSize: 12,
      background: '#B73C3C', //theme.RED
      color: '#E2E2E2', //theme.LIGHT
    },
  },
};
