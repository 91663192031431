import {
  faCheck,
  faTrashAlt,
  faPlus,
  faFolderTree,
  faEyeSlash,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState, useRef} from 'react';
import toast from 'react-hot-toast';
import {View, Text, FlatList, TouchableOpacity} from 'react-native';

import AdminGalleryTile from './AdminGalleryTile.web';
import AssetViewer from './AssetViewer';
import CreateCartButton from './CreateCartButton.web';
import EtherButton, {TextColor} from './EtherButton';
import FaIcon from './FaIcon';
import Toolbar from './Toolbar';
import DeletePackModal from './deletePackModal';
import Search from '../components/Search';
import UploadInfoMenu from '../components/UploadInfoMenu.web';
import UploadZone from '../components/UploadZone';
import {useTheme} from '../context/ThemeContext';
import {useAssets} from '../context/assets-context/AssetsContext';
import {STATUS} from '../utils/common/constants';
import {ellipsify} from '../utils/common/funcs';
import {useWindowDimensions} from '../utils/common/hooks';
import {showHidePack} from '../utils/common/packs';

const TILE_HEIGHT = 200;
const TILE_WIDTH = 270;
const TILE_SPACING = 20;

const loadingAsset = {
  thumb: {key: 'somekey', url: '/missingimg.jpeg'},
  original: {key: 'somekey', url: '/missingimg.jpeg'},
  preview: {key: 'somekey', url: '/missingimg.jpeg'},
  mime: 'image/jpeg',
};
const loadingAssets = [
  {...loadingAsset, name: 'loading1'},
  {...loadingAsset, name: 'loading2'},
  {...loadingAsset, name: 'loading3'},
];

export default function AdminGallery({
  disabled = false,
  showEventList = false,
  showAddPackModal = false,
  assetsToMove = [],
  setAssetsToMove,
  loaded = false,
}) {
  const {navigate} = useNavigation();
  const {width} = useWindowDimensions();
  const [numColumns, setNumColumns] = useState(4);
  useEffect(() => {
    // Calculate how many tiles fit
    const colCount = Math.floor(width / (TILE_WIDTH + TILE_SPACING));
    setNumColumns(colCount - 1 || 1);
  }, [width]);
  const narrowControlArea = numColumns <= 2;

  const galleryWidth =
    numColumns * TILE_WIDTH + (numColumns + 1) * TILE_SPACING;

  const {
    events,
    currentEvent,
    packs,
    currentPack,
    assets,
    deletePack,
    deleteAssets,
    dispatch,
  } = useAssets();
  const movingAssets = assetsToMove?.length;
  const [assetQuery, setAssetQuery] = useState('');
  const [selection, setSelection] = useState([]);
  const [lastIndex, setLastIndex] = useState(null);
  const [shiftHeld, setShiftHeld] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(STATUS.IDLE);
  const {style, values} = useTheme(getThemedStyles);
  const uploadInfo = useRef({});

  /**
   * Filter the uploadInfo entries.
   *
   * @param {function(asset): boolean} filter - Filter callback that receives an asset object.
   * A truthy return value will remove the asset from the uploadInfo.
   */
  function filterUploadInfo(filter) {
    const uploadInfoArr = Object.entries(uploadInfo.current);

    uploadInfoArr.forEach((entry) => {
      const key = entry[0];
      const asset = entry[1];

      const result = filter(asset);

      if (result) delete uploadInfo.current[key];
    });
  }

  const filteredAssets =
    assetQuery.length > 0
      ? assets.filter((asset) =>
          asset.name.toLowerCase().includes(assetQuery.toLowerCase()),
        )
      : assets;

  const sortedAssets = [...filteredAssets].sort((a, b) => {
    if (a.favorite && !b.favorite) return -1;
    if (!a.favorite && b.favorite) return 1;
    return 0;
  });

  function toggleSelectAll() {
    const hasAllSelected = selection.length === sortedAssets.length;
    const newSelection = hasAllSelected ? [] : sortedAssets;
    setSelection(newSelection);
  }

  function onDelete() {
    setDeleteStatus(STATUS.BUSY);

    // Filtered asset mutates selection so it's up to date
    if (selection.length > 0) {
      const assetNames = selection.map((s) => s.name);

      assetNames.forEach((assetName) =>
        filterUploadInfo(
          (asset) =>
            asset.nameNoExt === assetName && asset.packName === currentPack,
        ),
      );

      deleteAssets(assetNames)
        .catch(() => {
          toast.error(`Failed to delete assets ${assetNames.join(', ')}`);
        })
        .finally(() => setDeleteStatus(STATUS.IDLE));
    } else {
      filterUploadInfo((asset) => asset.packName === currentPack);
      deletePack(currentEvent?.name, currentPack?.name)
        .catch(() => {
          toast.error(`Failed to delete pack ${currentPack}`);
        })
        .finally(() => setDeleteStatus(STATUS.IDLE));
    }
  }

  //Selection handler functions
  if (!selection.every((item) => sortedAssets.includes(item))) {
    setSelection(selection.filter((item) => sortedAssets.includes(item)));
  }

  function toggleSelect(item, index) {
    const isSelected = !!selection.find(
      (s) => s.original.url === item.original.url,
    );
    let itemsToAdd;

    if (shiftHeld) {
      if (index >= lastIndex) {
        itemsToAdd = sortedAssets.slice(lastIndex + 1, index + 1);
      } else {
        itemsToAdd = sortedAssets.slice(index, lastIndex);
      }
    } else {
      itemsToAdd = [item];
    }
    if (isSelected) {
      let newSelection = selection;
      itemsToAdd.forEach(
        (i) =>
          (newSelection = newSelection.filter(
            (s) => s.original.url !== i.original.url,
          )),
      );
      setSelection(newSelection);
    } else {
      setSelection([...selection, ...itemsToAdd]);
    }
    setLastIndex(index);
  }

  function downHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(true);
    }
  }

  function upHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  function viewLeft() {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = sortedAssets.length - 1;
    }
    setCurrentIndex(newIndex);
  }

  function viewRight() {
    let newIndex = currentIndex + 1;
    if (newIndex > sortedAssets.length - 1) {
      newIndex = 0;
    }
    setCurrentIndex(newIndex);
  }

  //Help the user create events and packs.
  if (!events?.length) {
    return (
      <View style={style.emptyPageTextbox}>
        <Text style={style.emptyPageHeader}>
          Create an event to get started
        </Text>
        <EtherButton
          text="Event Manager"
          style={style.button}
          textColor={TextColor.LIGHT}
          onPress={() => navigate('Event Manager')}
        />
      </View>
    );
  }
  if (!currentEvent) {
    return (
      <View style={style.emptyPageTextbox}>
        <Text style={style.emptyPageHeader}>Select an event to begin</Text>
        <EtherButton
          text="Event Manager"
          style={style.button}
          textColor={TextColor.LIGHT}
          onPress={() => navigate('Event Manager')}
        />
      </View>
    );
  }
  if (!packs || packs.length < 1) {
    return (
      <View style={style.emptyPageTextbox}>
        <UploadZone uploadInfo={uploadInfo} />
        <Text style={style.emptyPageHeader}>No packs found</Text>
        <EtherButton
          text="Create a Pack"
          style={style.button}
          textColor={TextColor.LIGHT}
          onPress={() => showAddPackModal(true)}
        />
        <Text style={style.emptyPageSubtext}>
          You can also click the folder icon on the left pane to create packs
        </Text>
      </View>
    );
  }
  if (!currentPack) {
    return (
      <View style={style.emptyPageTextbox}>
        <UploadZone uploadInfo={uploadInfo} />
        <Text style={style.emptyPageHeader}>No pack selected</Text>
        <Text style={style.emptyPageSubtext}>
          Choose a pack from the list on the left
        </Text>
      </View>
    );
  }

  const allSelected = selection.length === sortedAssets.length;

  return (
    <View style={style.mainView}>
      {movingAssets ? (
        <View style={style.disableShield} transparent>
          <TouchableOpacity
            style={style.touchStopper}
            onPress={() => setAssetsToMove([])}
          >
            <Text style={style.tipText}>
              {showEventList ? '↩ Go back' : '↩ View events'}
            </Text>
            <Text style={style.tipText}>
              {showEventList ? '↩ Choose an event' : '↩ Choose a pack'}
            </Text>
            <EtherButton
              style={style.cancelButton}
              onPress={() => setAssetsToMove([])}
            >
              <Text style={style.cancelText}>Cancel</Text>
            </EtherButton>
          </TouchableOpacity>
        </View>
      ) : (
        <UploadZone uploadInfo={uploadInfo} />
      )}
      {Object.keys(uploadInfo.current).length !== 0 ? (
        <View style={style.infoMenuContainer}>
          <UploadInfoMenu
            uploadInfo={uploadInfo}
            inProgress={uploadInfo?.length}
          />
        </View>
      ) : null}
      <style type="text/css">
        {/* Prevent users from dragging elements in the admin gallery */}
        {`
          div {
            user-select: none;
          }
          input {
            user-select: text;
          }
        `}
      </style>
      <AssetViewer
        visible={!!sortedAssets[currentIndex]}
        asset={sortedAssets[currentIndex]}
        index={currentIndex}
        onPressClose={() => setCurrentIndex(null)}
        onPressLeft={viewLeft}
        onPressRight={viewRight}
      />
      <DeletePackModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={onDelete}
      />
      <View style={style.galleryControlBar}>
        <View style={style.galleryControls}>
          <Text style={style.fileCountText}>
            {ellipsify(
              `${
                !selection.length
                  ? assets.length + ' files'
                  : selection.length + '/' + assets.length + ' files'
              }`,
              22,
            )}
          </Text>
          <Search
            placeholder="Filter by name"
            onChangeText={(value) => setAssetQuery(value)}
            style={style.searchBar}
            textboxStyle={style.searchBarTextbox}
          />
          <Toolbar style={style.toolbar} narrow={narrowControlArea}>
            {assets.length ? <CreateCartButton /> : null}

            <Toolbar.Button
              icon={faTrashAlt}
              text={selection.length ? 'Delete Selected' : 'Delete Pack'}
              onPress={() => setShowDeleteModal(true)}
              status={deleteStatus}
              style={style.deleteButton}
            />

            {assets.length ? (
              <Toolbar.CheckBox
                onToggle={toggleSelectAll}
                text={allSelected ? 'Select none' : 'Select All'}
                icon={allSelected ? faCheck : null}
                style={style.checkBoxStyle}
              />
            ) : null}

            {assets.length && !selection?.length ? (
              <Toolbar.Button
                icon={currentPack?.active ? faEyeSlash : faEye}
                text={`${currentPack.active ? 'Hide' : 'Show'} in store`}
                onPress={() => {
                  const {name, _id} = currentPack;
                  const newActive = !currentPack?.active;
                  showHidePack(currentPack._id, newActive)
                    .then(() => {
                      dispatch({
                        type: 'pack.active',
                        value: {packId: _id, active: newActive},
                      });
                      toast.success(
                        `Pack ${name} will now be ${
                          newActive ? 'shown' : 'hidden'
                        } in store`,
                      );
                    })
                    .catch((error) => {
                      console.error('Error hiding pack:', error);
                      toast.error(
                        `Failed to ${newActive ? 'show' : 'hide'} pack`,
                      );
                    });
                }}
                style={style.deleteButton}
              />
            ) : null}

            {selection.length ? (
              <Toolbar.Button
                icon={faFolderTree}
                text="Move Selected"
                onPress={() => setAssetsToMove(selection)}
                style={style.deleteButton}
              />
            ) : null}
          </Toolbar>
          <UploadZone
            uploadInfo={uploadInfo}
            buttonMode
            style={style.uploadButton}
          >
            <Text style={style.uploadButtonText}>Upload</Text>
          </UploadZone>
        </View>
      </View>
      {!currentPack?.active ? (
        <View style={style.packHiddenContainer}>
          <FaIcon icon={faEyeSlash} size={24} color={values.FIRST} />
          <Text style={style.packHiddenCopy}>
            This pack is hidden from the storefront
          </Text>
        </View>
      ) : null}
      <div style={style.listContainer}>
        <div style={style.staticListContainer}>
          <FlatList
            data={
              assets === null
                ? loadingAssets
                : [...sortedAssets, {name: 'Add Asset', addAsset: true}]
            }
            keyExtractor={(asset = {}) => asset.name}
            numColumns={numColumns}
            key={numColumns}
            // Make the parent in charge of scrolling
            style={{overflow: 'hidden', paddingBottom: 20}}
            ListEmptyComponent={
              <Text style={style.noImages}>Drag and drop your stuff here!</Text>
            }
            renderItem={({item, index}) => {
              if (item.addAsset) {
                return (
                  <UploadZone
                    uploadInfo={uploadInfo}
                    buttonMode
                    style={style.addSquare}
                  >
                    <Text style={style.addSquareText}>Upload</Text>
                    <FaIcon icon={faPlus} color={values.FIRST} size={35} />
                    <Text style={style.addSquareText}>
                      {ellipsify(currentEvent.name, 15)}
                    </Text>
                    <Text style={style.addSquareText}>
                      {ellipsify(currentPack.name, 15)}
                    </Text>
                  </UploadZone>
                );
              }
              return (
                <AdminGalleryTile
                  style={[
                    style.galleryTile,
                    {height: TILE_HEIGHT, width: TILE_WIDTH},
                  ]}
                  source={item.thumb.url}
                  meta={item}
                  selected={selection.find(
                    (s) => s.original.url === item.original.url,
                  )}
                  onSelectPress={() => toggleSelect(item, index)}
                  onPress={() => setCurrentIndex(index)}
                  preDeleteCallback={() =>
                    filterUploadInfo(
                      (asset) =>
                        asset.nameNoExt === item.name &&
                        asset.packName === currentPack,
                    )
                  }
                  moveAsset={() => setAssetsToMove([item])}
                />
              );
            }}
          />
          {assets?.length ? (
            <View style={[style.footer, {width: galleryWidth}]} />
          ) : null}
        </div>
      </div>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  addSquare: {
    width: 270,
    height: 200,
    margin: 20,
    borderColor: theme.FIRST,
    borderWidth: 4,
    borderRadius: 10,
    borderStyle: 'dashed',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addSquareText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.FIRST,
    marginLeft: 5,
    textAlign: 'center',
  },
  breakline: {
    width: 150,
    borderWidth: 1,
    borderColor: theme.BGFIRST,
  },
  breakpoint: {
    color: theme.BGFIRST,
  },
  button: {
    marginVertical: 20,
  },
  buttonText: {
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  cancelButton: {
    marginLeft: 80,
    height: 40,
    width: 80,
    borderColor: theme.FIRST,
    backgroundColor: theme.SECOND,
  },
  cancelText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    textAlign: 'center',
  },
  checkBoxStyle: {
    paddingRight: 20,
  },
  deleteButton: {
    width: 130,
  },
  disableShield: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 9999,
  },
  emptyPageHeader: {
    textAlign: 'center',
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  emptyPageSubtext: {
    textAlign: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  emptyPageTextbox: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '25vh',
  },
  fileCountText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    userSelect: 'text',
    marginRight: 10,
    mobile: {
      paddingRight: 10,
      paddingTop: 10,
    },
  },
  footer: {
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  galleryControlBar: {
    width: '100%',
    backgroundColor: theme.BGFIRST,
  },
  galleryControls: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    backgroundColor: theme.BGFIRST,
    alignItems: 'center',
    paddingHorizontal: 16,
    maxWidth: '100%',
    mobile: {
      paddingHorizontal: 8,
    },
  },
  galleryTile: {
    marginLeft: TILE_SPACING,
    marginTop: 20,
    zIndex: -10,
    position: 'relative',
  },
  infoMenuContainer: {
    bottom: 30,
    right: 30,
    position: 'absolute',
    zIndex: 1,
  },
  listContainer: {
    flex: 1,
    position: 'relative',
    overflowX: 'hidden',
  },
  mainView: {
    flex: 1,
  },
  noImages: {
    marginTop: 280,
    alignSelf: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
  },
  packHiddenContainer: {
    width: '100%',
    backgroundColor: theme.YELLOW,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },
  packHiddenCopy: {
    marginLeft: 12,
  },
  searchBar: {
    marginRight: 10,
    paddingVertical: 10,
    width: 250,
    mobile: {
      padding: 0,
      width: '100%',
    },
  },
  searchBarTextbox: {
    borderRadius: 15,
    borderWidth: 2,
    width: '100%',
  },
  staticListContainer: {
    position: 'absolute',
    inset: 0,
    overflow: 'hidden scroll',
  },
  tipText: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
    marginBottom: 70,
  },
  toolbar: {
    marginRight: 10,
    marginVertical: 10,
    mobile: {
      margin: 0,
    },
  },
  touchStopper: {
    width: '100%',
    height: '100%',
    paddingLeft: 30,
    paddingTop: 20,
  },
  uploadButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 10,
    height: 35,
    width: 85,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: 10,
    marginVertical: 10,
  },
  uploadButtonText: {
    fontSize: fontSize.body,
    color: theme.LIGHT,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
});
