import {ellipsify} from './funcs';
import etherFetch from '../ether-fetch/etherFetch';

const BASE_ROUTE = '/packs';

/**
 * List current user's events
 * @returns {Promise}
 */
export function findEvents() {
  return etherFetch(`/packs`).then((res) => res.json());
}

/**
 * List an event's packs'
 * @param {string} event - Event name
 */
export function findPacks(event) {
  return etherFetch(`/packs/${event}`).then((res) => res.json());
}
/**
 * Returns a promise for a list of assets within a pack and the price
 * @param {string} event - Event name
 * @param {string} packName - Pack name
 * @param {Object} config - Options for request
 */
export function getPack(event, packName, config = {}) {
  return new Promise((resolve, reject) => {
    if (!event || !packName) {
      reject(new Error('Missing required param'));
    }

    resolve(
      etherFetch(`/packs/${event}/${packName}`, config).then((res) =>
        res.json(),
      ),
    );
  });
}

/**
 * Returns a promise, when it resolves the event has been shown or hidden
 * @param {string} eventId
 * @param {boolean} active - true to show, false to hide
 * @returns {Promise<*>}
 */
export function showHidePack(packId, active) {
  return etherFetch(`${BASE_ROUTE}/pack/sale-status`, {
    method: 'PUT',
    body: {packId, active},
  }).then((req) => req.json());
}

/**
 * Returns a promise, when it resolves the pack has been deleted
 * @param {string} event - Event name
 * @param {string} packName - Pack name
 */
export function delPack(event, packName) {
  return etherFetch(`${BASE_ROUTE}/${event}/${packName}`, {
    method: 'DELETE',
    timeout: 0,
  }).then((res) => res.json());
}

/**
 * Returns a promise, when it resolves the asset has been deleted
 * @param {string} event - Event name
 * @param {string} packName - Pack name
 * @param {string} asset - Asset name, or names delimited by commas,like,this
 */
export function deleteAsset(event, packName, asset) {
  const route = `${BASE_ROUTE}/${event}/${packName}/${asset}`;
  return etherFetch(route, {method: 'DELETE', timeout: 0}).then((res) =>
    res.json(),
  );
}

/**
 * Returns a promise, when it resolved the asset has been renamed to the newName
 * @param {string} event - Event name
 * @param {string} packName - Pack name
 * @param {string} asset - Asset name
 * @param {string} newName - String to rename the asset to
 */
export function renameAsset(event, pack, asset, newName) {
  const route = `${BASE_ROUTE}/rename/asset/${event}/${pack}/${asset}`;
  return etherFetch(route, {
    method: 'PUT',
    body: {newName},
    timeout: 30,
  }).then((res) => res.json());
}

/**
 * Marks a specific asset as favorite by setting its "favorite" field to true
 * @param {string} event - Event name
 * @param {string} packName - Pack name
 * @param {string} asset - Asset name
 * @returns {Promise}
 */
export function favoriteAsset(event, pack, asset) {
  const route = `${BASE_ROUTE}/${event}/${pack}/${asset}/favorite`;
  return etherFetch(route, {
    method: 'PUT',
  }).then((res) => res.json());
}

/**
 * Unarks a specific asset as favorite by setting its "favorite" field to false
 * @param {string} event - Event name
 * @param {string} packName - Pack name
 * @param {string} asset - Asset name
 * @returns {Promise}
 */
export function unfavoriteAsset(event, pack, asset) {
  const route = `${BASE_ROUTE}/${event}/${pack}/${asset}/unfavorite`;
  return etherFetch(route, {
    method: 'PUT',
  }).then((res) => res.json());
}

/**
 * Move asset(s) to a destination pack
 * @param {string[]} assetIds - mongo IDs of assets to move
 * @param {string} packId - mongo ID of destination pack
 * @returns {Promise<Object>}
 */
export function moveAssets(packId, assetIds) {
  return etherFetch('/packs/move-assets', {
    method: 'POST',
    body: {
      assetIds,
      packId,
    },
  }).then((res) => res.json());
}

/**
 * Returns a promise, when it resolves the event has been renamed
 * @param {string} packId
 * @param {string} newName
 * @returns {Promise<RenamedEventResponse>}
 */
export function renamePack(packId, newName) {
  return etherFetch(`${BASE_ROUTE}/packs/rename`, {
    method: 'PUT',
    body: {packId, newName},
    timeout: 0,
  }).then((req) => req.json());
}

/**
 * Returns a promise, when it resolves the pack has been created
 * @param {string} event - Event name
 * @param {string} packName - Pack name
 */
export function addPack(event, packName) {
  return new Promise((resolve, reject) => {
    if (!packName) {
      reject(new Error('No pack name found'));
    }

    findPacks(event)
      .then((packList) => {
        if (packList.includes((item) => item.name === packName)) {
          reject(new Error('Duplicate'));
        }
      })
      .catch((err) => reject(err))
      .then(() =>
        etherFetch(`${BASE_ROUTE}/${event}/${packName}`, {method: 'POST'})
          .then((res) => res.json())
          .then(resolve)
          .catch(reject),
      );
  });
}

/**
 * Returns a promise, when it resolves the event has been deleted
 * @param {string} event - Event name
 */
export function delEvent(event) {
  const route = `${BASE_ROUTE}/${event}`;
  return etherFetch(route, {method: 'DELETE', timeout: 0}).then((res) =>
    res.json(),
  );
}

/**
 * Returns a promise, when it resolves  the event has been created
 * @param {string} name - Event name
 * @param {string} location - Event location
 */
export function addEvent(name, location) {
  return etherFetch(`${BASE_ROUTE}/event`, {
    method: 'POST',
    body: {
      name,
      location,
    },
  }).then((req) => req.json());
}

/**
 * Returns a promise, when it resolves the event has been renamed
 * @param {string} oldName
 * @param {string} newName
 * @returns {Promise<RenamedEventResponse>}
 */
export function renameEvent(oldName, newName) {
  return etherFetch(`${BASE_ROUTE}/events/rename`, {
    method: 'PUT',
    body: {oldName, newName},
  }).then((req) => req.json());
}

/**
 * Returns a promise, when it resolves the event has been shown or hidden
 * @param {string} eventId
 * @param {boolean} active - true to show, false to hide
 * @returns {Promise<*>}
 */
export function showHideEvent(eventId, active) {
  return etherFetch(`${BASE_ROUTE}/event/sale-status`, {
    method: 'PUT',
    body: {eventId, active},
  }).then((req) => req.json());
}

/**
 * Summarizes pack names to a shortened string
 *
 * @param {Object} orderData - hydrated order from api
 * @returns {string} / eg: 'Pack One, Pack Two... (+1 Items)'
 */
export function getPacksSummary(orderData, maxPacks = 2) {
  let result = '';

  const names = orderData.packNames;
  const packCount = names.length;
  if (packCount > maxPacks) {
    const firstNames = names.slice(0, maxPacks);
    const packsRemaining = names.length - maxPacks;
    result = `${firstNames}... (+${packsRemaining} Items)`;
  } else {
    result = names.join(', ');
  }
  return ellipsify(result, 28);
}
