import {faCheck} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import {Text, View} from 'react-native-web';

import EtherButton, {TextColor} from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import {createStripeAccount} from '../../utils/common/orders';

function openNewTab(url) {
  window.open(url, '_blank');
}

export function StripeQuarter({stripeId, stripeAccountUrl}) {
  const {style} = useTheme(getThemedStyles);
  const [stripeLoading, setStripeLoading] = useState(false);

  function connectStripe() {
    setStripeLoading(true);
    createStripeAccount().then(({signUpUrl}) => {
      setStripeLoading(false);
      window.open(signUpUrl.url, '_self');
    });
  }

  return (
    <View style={style.quarter}>
      {stripeId ? (
        <>
          <FaIcon
            icon={faCheck}
            size={28}
            color="green"
            style={{marginBottom: 10}}
          />
          <Text style={style.stripeTextDark}>Stripe Connected</Text>
          <EtherButton
            text="Manage Payments"
            textColor={TextColor.LIGHT}
            style={style.stripeLink}
            onPress={() => !stripeLoading && openNewTab(stripeAccountUrl)}
            status={stripeLoading ? STATUS.BUSY : STATUS.IDLE}
          />
        </>
      ) : (
        <EtherButton
          text="Connect Stripe"
          textColor={TextColor.LIGHT}
          status={stripeLoading ? STATUS.BUSY : STATUS.IDLE}
          onPress={connectStripe}
        />
      )}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  navIcon: {
    marginLeft: 5,
  },
  quarter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 2,
  },
  stripeLink: {
    padding: 5,
    marginTop: 20,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    textDecoration: 'none',
    color: theme.DARK,
  },
  stripeText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
  },
  stripeTextDark: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
});
