import etherFetch from '../ether-fetch/etherFetch';

const BASE_ROUTE = '/order';

/**
 * Returns a promise for creating a cart entry in db
 * @param {Object<Cart>} cart - Cart object to store in DB
 * @param {string} eventName - Cart object to store in DB
 */
export function createCart(cart, eventName, storeSlug) {
  return etherFetch([BASE_ROUTE, 'create'].join('/'), {
    body: {cart, eventName, storeSlug},
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
}

/**
 * Returns a promise for returning a cart entry from db
 * @param {string} id - MongoDB _id to retrieve
 */
export function getOrder(id) {
  return etherFetch([BASE_ROUTE, id].join('/')).then((res) => res.json());
}

/**
 * Returns a promise for a stripe url to create and link a stripe express account
 */
export function createStripeAccount() {
  return etherFetch([BASE_ROUTE, 'stripe-connect', 'create'].join('/'), {
    method: 'POST',
  }).then((res) => res.json());
}

/**
 * Returns a promise for which resolves to a stripe sessions token
 */
export function confirmStripeAccount(id) {
  return etherFetch([BASE_ROUTE, 'stripe-connect', 'link'].join('/'), {
    method: 'POST',
    body: {id},
  }).then((res) => res.json());
}

/**
 * Returns a promise for which resolves to a stripe sessions token
 */
export function createStripeSession(name, cartId, eventAccessCode) {
  return etherFetch([BASE_ROUTE, 'session'].join('/'), {
    body: {name, cartId, eventAccessCode},
    method: 'POST',
  }).then((res) => res.json());
}

/**
 * Returns a promise for which resolves to a stripe sessions token
 */
export function getStripeAccountLink() {
  return etherFetch([BASE_ROUTE, 'stripe-connect', 'login'].join('/')).then(
    (res) => res.json(),
  );
}

/**
 *
 * @returns {Array} - Array of orders for the current operator
 */
export function getOrders() {
  return etherFetch(BASE_ROUTE).then((res) => res.json());
}

/**
 *
 * @returns {Object} - Returns detailed order payload (hydraterOrder/payment-intent)
 */
export function getOrderDetails(orderId) {
  return etherFetch(`/order/${orderId}/payment-intent`).then((res) =>
    res.json(),
  );
}
