import {useEffect, useRef, useState, useCallback} from 'react';
import {Dimensions} from 'react-native';

/*
 *Fade in animation
 */
export function useFadeIn(ref) {
  const [animate, setAnimate] = useState(false);
  /**
   * {React.createRef()} - ref to a dom element
   */
  const fadeIn = useCallback(() => {
    const keyframes = [{opacity: '0'}, {opacity: '1'}];

    const animDuration = {
      duration: 2000,
      iterations: 1,
    };

    ref.current.animate(keyframes, animDuration);
  }, [ref]);

  useEffect(() => {
    if (animate) {
      fadeIn(ref);
      setAnimate(false);
    }
  }, [animate, fadeIn, ref]);

  return () => setAnimate(true);
}

/* Provides object {fontScale: #, width: #, height: #, scale: #, isLandscape: bool, isMobile: bool}
 *
 * Hook inspired by: https://www.reactnativeschool.com/building-a-dimensions-hook-in-react-native
 * Dimenions.get('screen') vs get('window') https://stackoverflow.com/questions/44978804/whats-the-difference-between-window-and-screen-in-the-dimensions-api
 */
export function useWindowDimensions() {
  const [windowDims, setScreenData] = useState(Dimensions.get('window'));
  const timerHandle = useRef(null);

  useEffect(() => {
    function onChange(result) {
      if (timerHandle.current) {
        clearTimeout(timerHandle.current);
      }
      // 34 ms is ~1 frame at 30 fps
      timerHandle.current = setTimeout(() => setScreenData(result.window), 34);
    }

    const {remove} = Dimensions.addEventListener('change', onChange);
    return remove;
  }, []);

  return {
    ...windowDims,
    isLandscape: windowDims.width > windowDims.height,
    isMobile: windowDims.width <= 768,
  };
}

/**
 *
 * @param {Object} windowDims - Window dims from react.Dimensions
 * @returns - If it's mobile or not
 */
function isMobileWindow(windowDims) {
  const {width} = windowDims;
  const result = width <= 768;
  return result;
}

/**
 *
 * @returns - if it's mobile; updates on breakpoint change
 */
export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(
    isMobileWindow(Dimensions.get('window')),
  );

  useEffect(() => {
    function onChange(result) {
      const freshIsMobile = isMobileWindow(result.window);
      if (freshIsMobile !== isMobile) {
        setIsMobile(freshIsMobile);
      }
    }

    const {remove} = Dimensions.addEventListener('change', onChange);
    return remove;
  }, [isMobile]);

  return {isMobile};
}

/**
 *
 * @returns - Number of times the given size can fit on screen horizontally
 */
export function useCalcTileColumns(tileWidth) {
  const [fitWidth, setFitWidth] = useState(1);

  useEffect(() => {
    const updateColumns = () => {
      const {width} = Dimensions.get('window');
      setFitWidth(Math.floor(width / tileWidth) || 1);
    };
    updateColumns();
    Dimensions.addEventListener('change', updateColumns);

    return () => {
      Dimensions.removeEventListener('change', updateColumns);
    };
  }, [tileWidth]);

  return fitWidth;
}
