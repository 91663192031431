import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import {useIsFocused} from '@react-navigation/native';
import React, {useState, useRef, useEffect, useCallback} from 'react';
import toast from 'react-hot-toast';
import {
  FlatList,
  Modal,
  Text,
  View,
  Image,
  Keyboard,
  ScrollView,
  ActivityIndicator,
} from 'react-native';

import DirList from '../../components/DirList';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import GalleryTile from '../../components/GalleryTile';
import IdleTimer from '../../components/IdleTimer';
import ImageViewerComponent from '../../components/ImageViewer';
import {useCart} from '../../context/CartContext';
import {useTheme} from '../../context/ThemeContext';
import {SELECT, HEADER_HEIGHT_KIOSK} from '../../utils/common/constants';
import {useWindowDimensions} from '../../utils/common/hooks';
import etherFetch from '../../utils/ether-fetch/etherFetch';

const TILE_SIZE = 190;
const TILE_SPACING = 13;
const CONTAINER_PERCENT = 0.74;

export default function Gallery({navigation: {navigate}, route}) {
  const galleryRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [assets, setAssets] = useState([]);
  const [packs, setPacks] = useState([]);
  const [event, setEvent] = useState(null);
  const [store, setStore] = useState(null);
  const [currentPack, setCurrentPack] = useState(null);
  const [selectable, setSelectable] = useState(SELECT.DEFAULT);
  const {cart, addItem, toggleSelect, clearCart} = useCart();
  const {style, values} = useTheme(getThemedStyles);
  const {isLandscape, width} = useWindowDimensions();
  const isFocused = useIsFocused();
  const GALLERY_COLUMNS = isLandscape ? 3 : 2;
  const showCount = cart.length > 0;

  const {storeSlug, eventId} = route.params;

  // Calculate tile measurements
  const containerWidth = width * CONTAINER_PERCENT;
  const tileTotalWidth = TILE_SIZE + TILE_SPACING;
  const numColumns = Math.floor(containerWidth / tileTotalWidth);
  const remainder = containerWidth % tileTotalWidth;
  const remainderDivided = remainder / numColumns;

  useEffect(() => {
    async function loadStore() {
      setLoaded(false);
      try {
        const res = await etherFetch('/store/event-with-packs', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            storeSlug,
            eventId,
          },
        });
        const eventRes = await res.json();
        setPacks(eventRes.packs);
        setCurrentPack(eventRes.packs[0]);
        setEvent(eventRes.event);
        setStore(eventRes.store);
        localStorage.setItem('storeName', eventRes.store.orgname);
        localStorage.setItem('eventName', eventRes.event.name);
        localStorage.setItem('kioskUrl', window.location.href);
        localStorage.setItem('storeSlug', storeSlug);
      } catch (error) {
        console.error('Error loading kiosk:', error);
        toast.error('Error loading kiosk. Please contact support.');
      }
      setLoaded(true);
    }

    if (storeSlug && eventId) {
      loadStore();
    }
  }, [storeSlug, eventId]);

  const loadAssets = useCallback(
    async (packName) => {
      const res = await etherFetch('/store/assets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          storeSlug,
          packName,
        },
      });
      const assetRes = await res.json();

      const sortedAssets = [...assetRes.assets].sort((a, b) => {
        if (a.favorite && !b.favorite) return -1;
        if (!a.favorite && b.favorite) return 1;
        return 0;
      });

      setAssets(sortedAssets);
    },
    [storeSlug],
  );

  useEffect(() => {
    if (currentPack) {
      loadAssets(currentPack.name);
    }
  }, [currentPack, loadAssets]);

  function goToCart() {
    if (!showCount) {
      addPack();
    }
    navigate('Cart');
  }

  function addPack() {
    addItem({
      name: currentPack.name,
      packId: currentPack.id,
      event: event.name,
      assets,
    });
  }

  function closeImageViewer() {
    // FlatList counts each row as an index, NOT each item
    const numRows = Math.ceil(assets.length / GALLERY_COLUMNS);
    const scrollIndex = Math.floor(selectedIndex / numRows);
    galleryRef.current.scrollToIndex({index: scrollIndex});

    setSelectedIndex(null);
  }

  function changeSelectedPack(newPack) {
    setCurrentPack(newPack);
    Keyboard.dismiss();
  }

  if (!loaded) {
    return (
      <ScrollView contentContainerStyle={style.loadingContainer}>
        <ActivityIndicator
          size={34}
          color={values.FIRST}
          style={{marginBottom: 40}}
        />
        <Text style={style.loadingMessage}>Loading...</Text>
      </ScrollView>
    );
  }
  return (
    <IdleTimer active={isFocused && cart.length > 0} onTimeout={clearCart}>
      <View style={style.masterContainer}>
        <View style={style.dirListContainer}>
          <DirList
            msgHeader="No Packs Found"
            style={style.galleryDirList}
            searchStyle={style.searchStyle}
            dirs={packs}
            onPress={(pack) => changeSelectedPack(pack)}
            selected={currentPack?.name}
          />
        </View>
        <View style={style.controlContainer}>
          <EtherButton
            style={style.addToCartButton}
            onPress={addPack}
            disabled={assets.length < 1}
          >
            <Text style={style.addToCartButtonText}>+ Add pack to cart</Text>
          </EtherButton>
          <EtherButton
            style={style.continueToCartButton}
            onPress={goToCart}
            disabled={assets.length < 1}
          >
            <View style={style.cartButton}>
              <FaIcon
                style={style.cartButtonIcon}
                icon={faShoppingCart}
                size={30}
              />
              {showCount ? (
                <View style={style.cartCounterContainer}>
                  <Text style={style.cartCounterText}>{`${cart.length}`}</Text>
                </View>
              ) : null}
            </View>
            <Text style={style.addToCartButtonText}>
              {showCount ? 'Continue to Cart' : 'Buy Now'}
            </Text>
          </EtherButton>
        </View>
        <View style={style.assetsBackground}>
          <Image
            resizeMode="contain"
            source={store?.logo}
            style={[style.logoBackground, {top: '10%'}]}
          />
          <FlatList
            refreshing={!loaded}
            data={assets}
            ref={galleryRef}
            keyExtractor={(asset) => asset.name}
            key={numColumns}
            numColumns={numColumns}
            ListEmptyComponent={
              <View style={{alignItems: 'center'}}>
                <Text style={style.noImages}>This pack is empty</Text>
                <Text style={style.noImagesSub}>
                  Go to ethermedia.app to upload content in the Asset Manager
                </Text>
              </View>
            }
            showsVerticalScrollIndicator={false}
            renderItem={({
              item,
              item: {name, thumb, mime, duration},
              index,
            }) => {
              return (
                <GalleryTile
                  width={TILE_SIZE + remainderDivided}
                  height={TILE_SIZE + remainderDivided}
                  source={thumb.url}
                  index={index}
                  mime={mime}
                  duration={duration}
                  onPress={() => setSelectedIndex(index)}
                  onLongPress={() => {
                    setSelectable(
                      selectable === SELECT.DEFAULT
                        ? SELECT.SELECTABLE
                        : SELECT.DEFAULT,
                    );
                  }}
                  onSelectPress={() => toggleSelect(item)}
                  selected={
                    cart.find((itm) => itm.name === name)
                      ? SELECT.SELECTED
                      : selectable
                  }
                />
              );
            }}
          />
        </View>
        <Modal
          visible={!!selectedIndex || selectedIndex === 0}
          transparent
          ariaHideApp={false}
        >
          <ImageViewerComponent
            onClose={closeImageViewer}
            assets={assets}
            currentIndex={selectedIndex}
            setIndex={setSelectedIndex}
          />
        </Modal>
      </View>
    </IdleTimer>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  addToCartButton: {
    minWidth: 280,
    width: '30%',
    height: 50,
    borderRadius: 5,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    marginRight: 15,
  },
  addToCartButtonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.LIGHT,
  },
  assetsBackground: {
    width: '100%',
    height: '91%',
    flex: 1,
  },
  cartButton: {
    marginRight: 10,
  },
  cartButtonIcon: {
    color: theme.LIGHT,
  },
  cartCounterContainer: {
    right: -6,
    position: 'absolute',
    backgroundColor: theme.RED,
    height: 20,
    width: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  cartCounterText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.tiny,
    color: theme.LIGHT,
  },
  closePreview: {
    position: 'absolute',
    padding: 10,
    right: 0,
    top: 0,
  },
  continueToCartButton: {
    minWidth: 280,
    width: '30%',
    height: 50,
    borderRadius: 5,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    flexDirection: 'row',
  },
  controlContainer: {
    alignSelf: 'flex-end',
    position: 'absolute',
    flexDirection: 'row',
    width: '74%',
    left: '25%',
    height: '8%',
    marginLeft: 13,
    justifyContent: 'space-between',
  },
  dirListContainer: {
    width: '25%',
    height: 'auto',
    backgroundColor: theme.FIRST,
  },
  galleryDirList: {
    height: '100%',
  },
  imageViewer: {
    backgroundColor: theme.DARK,
    justifyContent: 'center',
    fontSize: 28,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  imageViewerText: {
    fontSize: 28,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  imageViewerTextContainer: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  loadingMessage: {
    textAlign: 'center',
  },
  logoBackground: {
    position: 'absolute',
    width: 600,
    height: 600,
    alignSelf: 'center',
    opacity: 0.2,
  },
  masterContainer: {
    flexDirection: 'row',
    backgroundColor: theme.BGSECOND,
    height: `calc(100vh - ${HEADER_HEIGHT_KIOSK}px)`,
  },
  mutedText: {
    zIndex: 9999,
    position: 'absolute',
    top: '90%',
    alignSelf: 'center',
    fontSize: 18,
    fontFamily: 'NotoSans_Bold',
    letterSpacing: 6,
    color: theme.LIGHT,
    textShadowColor: theme.DARK,
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 0.1,
  },
  noImages: {
    color: theme.DARK,
    fontSize: 36,
    fontFamily: 'NotoSans_Bold',
    marginTop: 250,
    marginBottom: 20,
  },
  noImagesSub: {
    color: theme.DARK,
    fontSize: 18,
    fontFamily: 'NotoSans_Regular',
  },
  previewText: {
    zIndex: 9999,
    position: 'absolute',
    top: '95%',
    alignSelf: 'center',
    fontSize: 18,
    fontFamily: 'NotoSans_Bold',
    letterSpacing: 6,
    color: theme.LIGHT,
    textShadowColor: theme.DARK,
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 0.1,
  },
  searchStyle: {
    borderRadius: 10,
    margin: 10,
    height: 40,
    width: '95%',
    alignSelf: 'center',
  },
});
