import {faClose} from '@fortawesome/free-solid-svg-icons';
import PT from 'prop-types';
import React from 'react';
import {View, TouchableOpacity, Modal, Text, FlatList} from 'react-native';

import EtherButton, {TextColor} from './EtherButton';
import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';

EtherDialog.propTypes = {
  show: PT.bool,
  onClose: PT.func,
  children: PT.node,
  header: PT.string,
  subheader: PT.string,
  controls: PT.arrayOf(
    PT.shape({
      text: PT.string,
      onPress: PT.func,
      variant: PT.string,
      status: PT.oneOf(Object.values(STATUS)),
    }),
  ),
  closeCross: PT.bool,
  style: PT.oneOfType([PT.object, PT.array, PT.number]),
  backdropStyle: PT.oneOfType([PT.object, PT.array, PT.number]),
};
export default function EtherDialog({
  show,
  onClose,
  children,
  header,
  subheader,
  controls,
  transparent = true,
  closeCross = true,
  style: overrides,
  backdropStyle,
  ...rest
}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <Modal
      useNativeDriver={false}
      animationType="fade"
      transparent={transparent}
      visible={show}
      onRequestClose={closeCross ? onClose : null}
      ariaHideApp={false}
      {...rest}
    >
      <TouchableOpacity
        style={[style.modalBackdrop, backdropStyle]}
        onPress={onClose}
        activeOpacity
      />
      <TouchableOpacity style={style.modalContainer} activeOpacity={1}>
        <View style={[style.modal, overrides]}>
          {closeCross ? (
            <TouchableOpacity style={style.closeButton} onPress={onClose}>
              <FaIcon icon={faClose} color={values.FIRST} size={30} />
            </TouchableOpacity>
          ) : null}
          <View style={style.header}>
            <Text style={style.headerText}>{header}</Text>
            <Text style={style.subText}>{subheader}</Text>
          </View>
          {children}
          {controls ? (
            <FlatList
              numColumns={controls?.length}
              data={controls}
              keyExtractor={(item) => item.text}
              renderItem={({item: {text, variant, ...itemRest}}) => (
                <EtherButton
                  text={text}
                  textColor={
                    variant === 'primary' ? TextColor.LIGHT : TextColor.DARK
                  }
                  style={[
                    style.button,
                    variant === 'primary'
                      ? {}
                      : {backgroundColor: values.BGFIRST},
                  ]}
                  {...itemRest}
                />
              )}
            />
          ) : null}
        </View>
      </TouchableOpacity>
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  button: {
    margin: 6,
  },
  buttonText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
  },
  closeButton: {
    backgroundColor: theme.BGFIRST,
    position: 'absolute',
    top: 10,
    right: 10,
  },
  header: {
    justifyContent: 'center',
    marginBottom: 10,
  },
  headerText: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 5,
    textAlign: 'center',
  },
  modal: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 10,
    padding: 20,
    paddingHorizontal: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBackdrop: {
    inset: 0,
    position: 'absolute',
    backgroundColor: theme.BLACKGROUND,
    opacity: 0.8,
  },
  modalContainer: {
    margin: 'auto',
  },
  subText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    textAlign: 'center',
  },
});
