import toast from 'react-hot-toast';

function showFallbackError(url, status, statusText, fetchParams) {
  const toastMessage = `Please try again later\nNetwork error: ${status} ${statusText}`;

  const prettyParams = JSON.stringify(fetchParams, null, 2);
  const errorMessage = [
    `Network error status: ${status}`,
    `URL: ${url}`,
    `Params: ${prettyParams}`,
    `Response: Invalid response body`,
  ]
    .join('\n')
    .replace('\\', '');

  toast.error(toastMessage);
  console.error(errorMessage);
}

// Error helper for network toasts and logs.
// When using axios, simply pass the axios rejection to res and leave fetchParams undefined.
export default function showNetworkError(res, fetchParams) {
  if (!res) {
    return showFallbackError(
      'Unknown url',
      'Unknown status, likely disconnected',
      'unknown status text',
      fetchParams,
    );
  }

  let axiosRes;
  if (res.isAxiosError) {
    axiosRes = {
      ...res?.response,
      url: res?.response.config?.url,
      json: () => new Promise((resolve) => resolve(res.response.data)),
    };
  }

  const {url, status, statusText} = axiosRes || res;

  (axiosRes || res)
    .json()
    .then((parsed) => {
      let message = parsed?.msg || 'Please try again later';
      if (Array.isArray(message)) message = message.join(', ');
      const toastMessage = `${message}\nNetwork error: ${status} ${statusText}`;

      const prettyRes = JSON.stringify(parsed, null, 2);
      const prettyParams = JSON.stringify(res.config || fetchParams, null, 2);
      const errorMessage = [
        `Network error status: ${status}`,
        `URL: ${url}`,
        `Params: ${prettyParams}`,
        `Response: ${prettyRes}`,
      ]
        .join('\n')
        .replace('\\', '');

      toast.error(toastMessage);
      console.error(errorMessage);
    })
    .catch(() => {
      showFallbackError(url, status, statusText, fetchParams);
    });
}
