import React, {useEffect, useState} from 'react';
import {View, Text, FlatList} from 'react-native';

import {OrderDesktop} from './OrderDesktop';
import PageMenu from './PageMenu.web';
import {useTheme} from '../../context/ThemeContext';

export default function TableList({data, pageSize, style: overrides}) {
  const {style} = useTheme(getThemedStyles);
  const [pageStart, setPageStart] = useState(0);

  const pageData = [...data].splice(pageStart, pageSize);
  const currentPage = pageStart > 0 ? pageStart / pageSize : pageStart;

  // Hide left arrow when it would page into the negative
  const showLeft = pageStart - pageSize >= 0;
  // Hide right arrow when it would page out of bounds
  const showRight = pageStart + pageSize <= data.length;

  useEffect(() => {
    setPageStart(0);
  }, [data]);

  return (
    <View style={[style.mainContainer, overrides]}>
      <View style={style.topBar}>
        <View style={style.cellDate}>
          <Text style={style.headerText}>Purchased</Text>
        </View>
        <View style={style.cellEvent}>
          <Text style={style.headerText}>Event & Packs</Text>
        </View>
        <View style={style.cellOrderID}>
          <Text style={style.headerText}>Order ID</Text>
        </View>
        <View style={style.cellPrice}>
          <Text style={style.headerText}>Total</Text>
        </View>
        <View style={style.cellStatus}>
          <Text style={style.headerText}>D/L</Text>
        </View>
      </View>
      <FlatList
        data={pageData}
        key={pageData}
        renderItem={({item, ...rest}) => <OrderDesktop orderData={item} />}
        keyExtractor={(item) => item.orderNumber}
        style={{paddingVertical: 10}}
      />
      <Text style={style.timeZone}>
        Time shown in {new Date().toString().match(/([A-Z]+[-][0-9]+.*)/)[0]}
      </Text>
      <View style={style.bottomBar}>
        <PageMenu
          onPrev={showLeft ? () => setPageStart(pageStart - pageSize) : null}
          onNext={showRight ? () => setPageStart(pageStart + pageSize) : null}
          page={currentPage + 1}
        />
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  bottomBar: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderTopWidth: 2,
    minHeight: 47,
  },
  cellDate: {
    width: 170,
  },
  cellEvent: {
    width: 220,
    alignItems: 'center',
  },
  cellOrderID: {
    width: 170,
  },
  cellPrice: {
    borderColor: theme.FIRST,
    alignItems: 'center',
    width: 40,
  },
  cellStatus: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 70,
  },
  headerText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    alignSelf: 'center',
  },
  mainContainer: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 12,
    overflowX: 'auto',
    maxWidth: 950,
  },
  timeZone: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.DARK,
    marginBottom: 20,
  },
  topBar: {
    backgroundColor: theme.SECOND,
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderColor: theme.FIRST,
    borderBottomWidth: 2,
    padding: 8,
  },
});
