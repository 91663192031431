import {AsYouType} from 'libphonenumber-js';
import {string, shape, func, bool} from 'prop-types';
import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {View} from 'react-native';

import EtherButton, {TextColor} from './EtherButton';
import EtherInput from './EtherInput';
import {useOperator} from '../context/OperatorContext';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';

UserInformation.propTypes = {
  userInfo: shape({
    fname: string,
    lname: string,
    email: string,
    phone: string,
    orgname: string,
  }),
  onSuccess: func,
  editableEmail: bool,
};

export function UserInformation({
  userInfo,
  onSuccess = () => {},
  editableEmail = true,
}) {
  const {style} = useTheme(getThemedStyles);
  const [firstName, setFirstName] = useState(userInfo?.fname || '');
  const [lastName, setLastName] = useState(userInfo?.lname || '');
  const [phone, setPhone] = useState(
    userInfo?.phone ? new AsYouType('US').input(userInfo?.phone) : '',
  );
  const [email, setEmail] = useState(userInfo?.email || '');
  const [orgName, setOrgName] = useState(userInfo?.orgname || '');
  const [status, setStatus] = useState(STATUS.IDLE);
  const {updateProfile} = useOperator();

  function onSubmit() {
    updateProfile({
      firstName,
      lastName,
      phone,
      email,
      orgName,
    })
      .then((res) => res.json())
      .then((parsed) => {
        toast.success(parsed?.msg);
        onSuccess();
      })
      .catch(() => {
        setStatus(STATUS.INVALID);
        setTimeout(() => setStatus(STATUS.IDLE), 3000);
      });
  }

  function processPhone(newPhone) {
    /*fix libphonenumber-js bug where backspacing a phone field
     *with string '(555)' would flash '(555' then go back to '(555)'
     */
    const removeParenthesis =
      phone.slice(-1) === ')' && newPhone.length < phone.length;
    newPhone = removeParenthesis ? newPhone.slice(0, 3) : newPhone;
    const formattedPhone = new AsYouType('US').input(newPhone);
    setPhone(formattedPhone);
  }

  return (
    <View>
      <EtherInput
        title="First Name"
        placeholder="ex: John"
        value={firstName}
        onChangeText={setFirstName}
      />

      <EtherInput
        title="Last Name"
        placeholder="ex: Doe"
        value={lastName}
        onChangeText={setLastName}
      />

      <EtherInput
        title="Phone"
        placeholder="ex: (555) 555-5555"
        maxLength={14}
        value={phone}
        onChangeText={processPhone}
      />
      <EtherInput
        title="Email"
        placeholder="ex: address@email.com"
        value={email}
        onChangeText={setEmail}
        editable={editableEmail}
      />
      <EtherInput
        title="Organization Name"
        placeholder="ex: company name"
        value={orgName}
        onChangeText={setOrgName}
      />
      <EtherButton
        buttonStatus={status}
        text="Submit"
        textColor={TextColor.LIGHT}
        onPress={() =>
          onSubmit({
            firstName,
            lastName,
            email,
            phone,
            orgName,
          })
        }
        style={style.updateButton}
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    borderColor: theme.DARK,
  },
  updateButton: {
    alignSelf: 'center',
    width: '80%',
    marginTop: 40,
    padding: 20,
  },
});
