import React from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {View} from 'react-native';

import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';

export default function SkeletonTile({
  icon,
  baseColor,
  highlightColor,
  ...rest
}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <View {...rest}>
      <SkeletonTheme
        baseColor={baseColor || values.BGFIRST}
        highlightColor={highlightColor || '#d2d2d2'}
      >
        {/* Image */}
        <FaIcon icon={icon} size={36} style={style.skeletonIcon} />
        <Skeleton height={131} borderRadius={0} />
        {/* Bottom */}
        <View style={style.skeletonBar}>
          <Skeleton width={260} height={12} />
          <View style={{flexDirection: 'row'}}>
            <Skeleton width={12} height={12} circle />
            <Skeleton width={240} height={12} style={{marginLeft: 8}} />
          </View>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Skeleton width={12} height={12} circle />
            <Skeleton width={116} height={12} style={{marginLeft: 8}} />
            <Skeleton width={116} height={12} style={{marginLeft: 8}} />
          </View>
        </View>
      </SkeletonTheme>
    </View>
  );
}

const getThemedStyles = (theme) => ({
  skeletonBar: {
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: 3,
    height: 62,
    backgroundColor: theme.BGFIRST,
  },
  skeletonIcon: {
    position: 'absolute',
    alignSelf: 'center',
    top: '25%',
    zIndex: 99999,
    color: theme.BGSECOND,
  },
});
