import etherFetch from '../ether-fetch/etherFetch';

const BASE_ROUTE = '/messaging';

/**
 * Returns a promise for sending a cartURL through email
 * @param {string} cartURL - Link to related checkout
 * @param {string} email - Email of recipient
 * @param {string} name - Name of recipient
 */
export function sendCartEmail(cartURL, email, name) {
  return new Promise((reject) => {
    etherFetch([BASE_ROUTE, 'sendcartemail'].join('/'), {
      body: {cartURL, email, name},
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => console.log(res))
      .catch((err) => reject(err));
  });
}
