import React from 'react';
import {View, Text, FlatList} from 'react-native';

import OrderMobile from './OrderMobile';
import {useTheme} from '../../context/ThemeContext';

export default function TableListMobile({data, style: overrides}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={[style.mainContainer, overrides]}>
      <Text style={style.timeZone}>
        Time shown in {new Date().toString().match(/([A-Z]+[-][0-9]+.*)/)[0]}
      </Text>
      <FlatList
        data={data}
        key={data}
        renderItem={({item}) => <OrderMobile orderData={item} />}
        keyExtractor={(item) => item.orderNumber}
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  mainContainer: {
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderColor: theme.FIRST,
    backgroundColor: theme.BGFIRST,
    flex: 1,
  },
  timeZone: {
    textAlign: 'center',
    width: '100%',
    borderColor: theme.FIRST,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.DARK,
    paddingVertical: 10,
    borderBottomWidth: 2,
  },
});
