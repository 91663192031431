import {
  faAngleLeft,
  faAngleRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {Text, View, Image, Pressable} from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';

import EtherButton from './EtherButton';
import FaIcon from '../components/FaIcon';
import {useTheme} from '../context/ThemeContext';

function RenderIndicator({style, values, onClose, onPressLeft, onPressRight}) {
  return (i, allSize) => (
    <View style={style.controls}>
      <EtherButton
        basic
        style={style.leftArrow}
        icon={faAngleLeft}
        iconColor={values.LIGHT}
        iconSize={48}
        onPress={onPressLeft}
      />
      <EtherButton
        basic
        style={style.rightArrow}
        icon={faAngleRight}
        iconColor={values.LIGHT}
        iconSize={48}
        onPress={onPressRight}
      />
      <Pressable style={style.close} onPress={onClose}>
        <FaIcon icon={faTimes} size={40} color={values.LIGHT} />
      </Pressable>
      <Text style={style.counter}>
        {i} / {allSize}
      </Text>
    </View>
  );
}

export default function ImageViewerComponent({
  onClose,
  assets,
  currentIndex,
  setIndex,
}) {
  const {style, values} = useTheme(getThemedStyles);

  function handlePressLeft() {
    setIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : assets.length - 1,
    );
  }

  function handlePressRight() {
    setIndex((prevIndex) =>
      prevIndex < assets.length - 1 ? prevIndex + 1 : 0,
    );
  }

  return (
    <View
      style={{
        position: 'absolute',
        flex: 1,
        width: '100%',
        height: '100%',
      }}
    >
      <ImageViewer
        saveToLocalByLongPress={false}
        renderIndicator={RenderIndicator({
          style,
          values,
          onClose,
          onPressLeft: handlePressLeft,
          onPressRight: handlePressRight,
        })}
        style={style.imageViewer}
        imageUrls={assets.map(({preview, thumb, mime, width, height}) => {
          const url = mime.includes('image') ? preview.url : thumb.url;
          return {
            url,
            width,
            height,
            // Scale video preview thumbs to fit video size
            ...(mime.includes('video') ? {width: '100%', height: '100%'} : {}),
          };
        })}
        index={currentIndex}
        renderImage={({style: renderImageStyle, ...rest}) => {
          if (assets[currentIndex]?.mime?.includes('video')) {
            return (
              <>
                <Text style={style.previewText}>THIS IS A VIDEO PREVIEW</Text>
                <video
                  style={{width: '100%', height: '88vh'}}
                  {...rest}
                  poster={assets[currentIndex]?.poster?.url}
                  src={assets[currentIndex]?.preview?.url}
                  onBack={() => {}}
                  autoPlay
                  loop
                  objectFit="contain"
                />
              </>
            );
          } else {
            return <Image style={renderImageStyle} {...rest} />;
          }
        }}
        enableSwipeDown
        onSwipeDown={onClose}
        onChange={setIndex}
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  close: {
    position: 'absolute',
    padding: 10,
    right: 0,
    top: 0,
    pointerEvents: 'auto',
  },
  controls: {
    pointerEvents: 'none',
    position: 'absolute',
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  counter: {
    fontSize: 28,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  imageViewer: {
    pointerEvents: 'auto',
    justifyContent: 'center',
  },
  leftArrow: {
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    left: 10,
    pointerEvents: 'auto',
  },
  previewText: {
    zIndex: 9999,
    position: 'absolute',
    alignSelf: 'center',
    textAlign: 'center',
    bottom: 40,
    fontSize: 22,
    fontFamily: 'NotoSans_Bold',
    letterSpacing: 6,
    color: theme.LIGHT,
    textShadowColor: theme.DARK,
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 0.1,
  },
  rightArrow: {
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    right: 10,
    pointerEvents: 'auto',
  },
});
